import React from "react";
import cx from "classnames";
import { useMsal } from "@azure/msal-react";

import { IonIcon } from "@ionic/react";
import { cashOutline } from "ionicons/icons";

import { useTuitionInfo, walletErrMsg } from "utils/hooks/wallet";

import { NxuAlert, NxuContentLoading } from "@nexford/nexford-ui-component-library";
import GetInTouch from "components/atom/get-in-touch";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import BillingInfo from "./billing-info";

import "./wallet.scss";

const WalletPage = () => {
  const { instance } = useMsal();

  const { data: tuitionData, isLoading: tuitionDataLoading, error: tuitionDataError } = useTuitionInfo(instance);

  return (
    <PageContent className="wallet__wrapper">
      <div className={cx("wallet__title")}>
        <>
          <IonIcon size="large" icon={cashOutline}></IonIcon>
          <h1>My Wallet</h1>
        </>
      </div>
      {tuitionDataLoading && <NxuContentLoading />}

      {!tuitionDataLoading && (
        <div className="wallet__inner" data-testid="inner-page">
          {!!tuitionDataError && (
            <NxuAlert message={tuitionData ? `${walletErrMsg.refetchTuitionInfo}` : tuitionDataError.message} />
          )}

          {!!tuitionData && (
            <div className="wallet__payment">
              <BillingInfo
                programStatus={tuitionData.status}
                nextInvoiceDate={tuitionData.nextBillDate}
                paymentFrequency={tuitionData.paymentFrequency}
                invoices={tuitionData.invoices}
                balance={tuitionData.balance}
                totalAmountDue={tuitionData.totalAmountDue}
                hasOldInvoices={tuitionData.hasOldInvoices}
                paymentGroup={tuitionData.paymentGroup}
              />
            </div>
          )}
          {!!tuitionDataError && <GetInTouch />}
        </div>
      )}
    </PageContent>
  );
};

export default WalletPage;
