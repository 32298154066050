import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { IonButton, IonIcon, IonInput, IonPopover } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

// Types
import { AgreementFileModel, SapFileModel, ReadmissionRequest } from "types/agreement";

// Utils
import { useAuthContext } from "utils/context/Auth";
import { useInitialisationContext } from "utils/context/Initialisation";
import { LocalRoutes } from "constants/routes";
import { NEXFORD_MAIL_SUCCESS } from "constants/external-routes";

// Components
import { NxuAlert, NxuContentLoading, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import CardPanel from "components/atom/card-panel";
import DocumentViewer from "components/molecule/document-viewer";
import GetInTouch from "components/atom/get-in-touch";
import RejectDialog from "./agreement-reject-modal";
import AgreementDocHeader from "./agreement-doc-header";
import SapPlan from "./sap-plan";

// API
import api from "../../../utils/campusUI.api";
// Types
import { UNSIGNED_STATE } from "./agreement.constants";
// Styles
import "./agreement.scss";

// Accept Form validation schema
const schema = yup.object({
  prepopulatedApplicantName: yup.string().required(),
  applicantName: yup
    .string()
    .required()
    .test(
      "names-match",
      "The name you have signed with must match the name we currently have in out records",
      (value, testContext) => {
        const prePop = testContext.parent.prepopulatedApplicantName;
        return value?.replace(/\s/g, "") === prePop.replace(/\s/g, "");
      },
    ),
});

interface AcceptFormInputs {
  applicantName: string;
  prepopulatedApplicantName: string;
}

/**
 * Learner agreement documents and forms
 */
const EnrollmentAgreement = () => {
  const navigate = useNavigate();
  const { getBearerToken } = useAuthContext();
  const { setReadmissionRequired, agreementAcceptance, setAgreementAcceptance } = useInitialisationContext();

  const [readmissionRequestLoading, setReadmissionRequestLoading] = useState(false);
  const [readmissionAgreement, setReadmissionAgreement] = useState<ReadmissionRequest | null>();
  const [readmissionLoadingComplete, setReadmissionLoadingComplete] = useState(false);
  const [sapDocLoading, setSapDocLoading] = useState(false);
  const [sapDoc, setSapDoc] = useState<SapFileModel>();
  const [agreementDocLoading, setAgreementDocLoading] = useState(false);
  const [agreementDoc, setAgreementDoc] = useState<AgreementFileModel | null>();
  const [loadingError, setLoadingError] = useState<string | null>();

  const [displaySapPlan, setDisplaySapPlan] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      applicantName: "",
      prepopulatedApplicantName: "",
    },
    resolver: yupResolver(schema),
  });

  /**
   * Initial request to determine admission status
   */
  const getActiveReadmissionRequest = useCallback(async () => {
    setReadmissionRequestLoading(true);
    // Check for the admission status in storage
    const readmissionString = sessionStorage.getItem("readmissionState");
    if (readmissionString) {
      const readmissionRequest = JSON.parse(readmissionString);
      if (readmissionRequest.requestState === UNSIGNED_STATE) {
        setReadmissionAgreement(readmissionRequest);
        setDisplaySapPlan(!!readmissionRequest.isSapPlanRequired);
      }
      setReadmissionRequestLoading(false);
    } else {
      // If there's no admisson state in store, then send an API request
      const response = await api.readmission.getActiveReadmissionRequest(getBearerToken);
      try {
        if (!response.ok) throw await response.json();
        if (response.status === 200) {
          const readmissionRequest: ReadmissionRequest = await response.json();
          if (readmissionRequest.requestState === UNSIGNED_STATE) {
            setReadmissionAgreement(readmissionRequest);
            setDisplaySapPlan(!!readmissionRequest.isSapPlanRequired);
          }
        }
        setReadmissionLoadingComplete(true);
      } catch {
        setLoadingError("Failed to load readmissionData");
      } finally {
        setReadmissionRequestLoading(false);
      }
    }
  }, [getBearerToken]);

  /**
   * User is Unsigned, Optional request for SAP documents
   */
  const getSapPlan = useCallback(async () => {
    setSapDocLoading(true);
    const response = await api.readmission.getSapPlan(getBearerToken);
    try {
      if (!response.ok) throw await response.json();
      const sapFileModel: SapFileModel = await response.json();
      setSapDoc(sapFileModel);
    } catch {
      setLoadingError("Failed to load Sap Plan");
    } finally {
      setSapDocLoading(false);
    }
  }, [getBearerToken]);

  /**
   * User is Unsigned, required request for enrollment doc and official name
   */
  const getEnrollmentAgreement = useCallback(async () => {
    setAgreementDocLoading(true);
    Promise.all([
      api.readmission.getOfficialName(getBearerToken),
      api.readmission.getEnrollmentAgreement(getBearerToken),
    ])
      .then(async ([officialNameResponse, enrollmentResponse]) => {
        const officalName = await officialNameResponse.json();
        setValue("prepopulatedApplicantName", officalName);
        const agreementFileModel: AgreementFileModel = await enrollmentResponse.json();
        setAgreementDoc(agreementFileModel);
      })
      .catch((err) => {
        console.warn(err);
        setLoadingError("Failed to load Enrollment Details");
      })
      .finally(() => {
        setAgreementDocLoading(false);
      });
  }, [getBearerToken, setValue]);

  const loadingStates = [agreementDocLoading, readmissionRequestLoading, sapDocLoading];
  const isLoading = loadingStates.some((state) => state);

  useEffect(() => {
    if (!readmissionLoadingComplete && !readmissionRequestLoading) {
      getActiveReadmissionRequest();
    }
  }, [getActiveReadmissionRequest, readmissionRequestLoading, readmissionLoadingComplete]);

  useEffect(() => {
    if (readmissionAgreement && !agreementDoc && !sapDoc && !isLoading) {
      if (readmissionAgreement.isSapPlanRequired) getSapPlan();
      getEnrollmentAgreement();
    }
  }, [readmissionAgreement, getSapPlan, getEnrollmentAgreement, agreementDoc, sapDoc, isLoading]);

  const acceptEnrollmentAgreement = async (officialName: string) => {
    setSubmitting(true);

    const response = await api.readmission.signEnrollmentAgreement(getBearerToken, { officialName });

    try {
      if (!response.ok) throw await response.json();
      // Update global context with admission status
      setAgreementAcceptance("accepted");
      setReadmissionRequired(false);
      window.location.replace(LocalRoutes.ENROLL_COMPLETION);
    } catch {
      setSubmissionError("Failed to sign agreement");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSave: SubmitHandler<AcceptFormInputs> = async (formValues) => {
    await acceptEnrollmentAgreement(formValues.applicantName);
  };

  const handleDecline = () => {
    setAgreementAcceptance("rejected");
    setReadmissionRequired(false);
    window.location.replace(LocalRoutes.ENROLL_COMPLETION);
  };

  const toggleEnrollmentAgreement = () => setDisplaySapPlan(!displaySapPlan);

  const submittingDisabled = !!loadingError || !!agreementAcceptance || submitting || isLoading;

  if (loadingError) {
    return (
      <PageContent className="agreement__no-request">
        <NxuAlert message={loadingError} />

        <GetInTouch type="general">
          <p>If this error continues, get in touch and we'll help</p>
        </GetInTouch>
      </PageContent>
    );
  }

  if (!readmissionAgreement) {
    return (
      <PageContent className="agreement__no-request">
        {isLoading && <NxuContentLoading loadingText="Loading Enrollment documents..." />}
        {!isLoading && (
          <>
            <h1>You have nothing to sign</h1>
            <NxuPrimaryButton onClick={() => navigate(LocalRoutes.HOME)}>Return to home</NxuPrimaryButton>
          </>
        )}
      </PageContent>
    );
  }

  return (
    <PageContent>
      {readmissionAgreement?.isSapPlanRequired && (
        <SapPlan
          displaySapPlan={displaySapPlan}
          sapDocumentLoading={sapDocLoading}
          sapFile={sapDoc?.sapFile}
          sapUrl={sapDoc?.sapUrl}
          toggleSapPlanDisplay={() => toggleEnrollmentAgreement()}
        />
      )}

      <div className={cx("agreement__wrapper", displaySapPlan && "agreement__wrapper--hidden")}>
        <AgreementDocHeader
          welcomeMsg="Nexford University Agreement"
          hasFile={!!agreementDoc}
          file={agreementDoc?.agreementFile as string}
          fileTitle="NXUAgreement.pdf"
          hasToggle={!!readmissionAgreement.isSapPlanRequired}
          isEnrollmentOpen={!displaySapPlan}
          toggleEvent={() => toggleEnrollmentAgreement()}
        />

        <div className="agreement__document">
          {agreementDocLoading && <NxuContentLoading />}
          {agreementDoc && <DocumentViewer frameTitle="Agreement Document" file={agreementDoc.agreementHtmlContent} />}
        </div>
        {agreementDoc && (
          <CardPanel className="agreement__form">
            <form onSubmit={handleSubmit(handleSave)} data-testid="agreement-form">
              <div className="agreement__form-title">
                <h3>Complete your enrollment by signing below</h3>
                <IonButton
                  id="enrollment-tooltip-trigger"
                  aria-label="Open agreement tooltip"
                  size="small"
                  shape="round"
                >
                  <IonIcon slot="icon-only" icon={informationCircleOutline} />
                </IonButton>
                <IonPopover
                  className="enrollment-tooltip"
                  trigger="enrollment-tooltip-trigger"
                  triggerAction="hover"
                  side="bottom"
                  alignment="start"
                >
                  <p>
                    Please, sign by typing your full legal name exactly as it is displayed above. If this is not your
                    full legal name, as displayed on your government-ID, please contact{" "}
                    <a href="mailto: admissions@nexford.org">{NEXFORD_MAIL_SUCCESS}</a>
                  </p>
                </IonPopover>
              </div>

              <div className="agreement__form-fields">
                <div>
                  <Controller
                    control={control}
                    name="prepopulatedApplicantName"
                    render={({ field }) => (
                      <IonInput
                        {...field}
                        fill="outline"
                        type="text"
                        readonly
                        aria-label="Sign by typing your full legal name*"
                        placeholder="Sign by typing your full legal name*"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="applicantName"
                    render={({ field }) => (
                      <IonInput
                        onIonChange={field.onChange}
                        onIonBlur={field.onBlur}
                        fill="outline"
                        type="text"
                        data-testid="applicant-signature"
                        placeholder="Sign by typing your full legal name*"
                        errorText={errors.applicantName?.message}
                        className={errors.applicantName ? "ion-touched ion-invalid" : ""}
                      />
                    )}
                  />
                </div>
              </div>

              {!!submissionError && <NxuAlert message={submissionError} />}
              <div className="agreement__form-toolbar">
                <IonButton size="large" disabled={!isValid || submittingDisabled} type="submit">
                  Sign
                </IonButton>
                <RejectDialog
                  dialogDisabled={submittingDisabled}
                  handleDecline={() => handleDecline()}
                  getBearerToken={getBearerToken}
                />
              </div>
            </form>
          </CardPanel>
        )}
      </div>
    </PageContent>
  );
};

export default EnrollmentAgreement;
