import React from "react";

import MyNxuAnimation from "components/atom/mynxu-animation/mynxu-animation";

import "./loading-interstitial.scss";

/**
 * Full page loading screen
 */
const LoadingInterstitial = () => (
  <div className="loading-interstitial">
    <MyNxuAnimation animation="loading" />
  </div>
);

export default LoadingInterstitial;
