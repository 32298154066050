import React from "react";
import { IonButton } from "@ionic/react";

import "./button-secondary.scss";

export interface SecondaryButtonProps {
  children: React.ReactNode;
  // Should be applied to provide an aria-label when btnText is uninituitive
  // eg btnText: 'X' should have ariaText: 'Close Modal'
  ariaText?: string;
  onClick?: React.MouseEventHandler;
  href?: string;
  target?: string;
  disabled?: boolean;
  type?: "button" | "submit";
  // Match to the following ionic values
  color?: string;
  expand?: "full" | "block" | undefined;
  size?: "small" | "default" | "large";
}

/**
 * Standardised secondary button
 */
const SecondaryButton = ({
  children,
  ariaText,
  onClick,
  href,
  disabled,
  target,
  type = "button",
  color = "secondary",
  expand,
  size = "small",
}: SecondaryButtonProps) => (
  <IonButton
    className="secondary-button"
    aria-label={ariaText}
    size={size}
    fill="outline"
    shape="round"
    color={color}
    expand={expand}
    disabled={disabled}
    type={type}
    href={href}
    target={target}
    onClick={onClick ? (e) => onClick(e) : undefined}
  >
    <div className="secondary-button__inner">{children}</div>
  </IonButton>
);

export default SecondaryButton;
