import React, { useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import cx from "classnames";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  IonButton,
  IonButtons,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonNote,
  IonIcon,
  IonInput,
} from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

import selfieExample from "assets/img/selfie_example.jpg";
import idExample from "assets/img/ID_example.jpg";

import { NxuAlert, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";

import { DocumentStatusTypeOptions, DocumentStatusTypes } from "types/learner";
import { useFileUploadService, useDocumentSave, useGovIdDocumentSave } from "utils/hooks/learners";
import { HttpError } from "utils/errors/HttpError";

// Styles
import "./upload-modal.scss";

export type UploadModalTypes = DocumentStatusTypes;

export interface UploadModalProps {
  isOpen: boolean;
  setUploadSuccessful: (isTrue: boolean) => void;
  closeModal: () => void;
  uploadType: UploadModalTypes | undefined;
}

interface UploadFormInputs {
  selectedFile: HTMLInputElement["files"];
  officialName?: string;
}

/**
 * Display a modal containing an upload form
 */
const UploadModal = (props: UploadModalProps) => {
  const { instance } = useMsal();
  const { isOpen, closeModal, setUploadSuccessful, uploadType } = props;
  const isSelfie = uploadType === DocumentStatusTypeOptions.photo;
  const isIdDoc = uploadType === DocumentStatusTypeOptions.id;

  const fileUploadService = useFileUploadService();
  const documentSave = useDocumentSave();
  const govIdDataSave = useGovIdDocumentSave();

  const [modalTitle, setModalTitle] = useState<string>();

  const [fileUploadSubmitting, setFileUploadSubmitting] = useState(false);
  const [uploadError, setUploadError] = useState<string>();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
    reset,
    resetField,
  } = useForm<UploadFormInputs>();

  useEffect(() => {
    if (isSelfie) setModalTitle("Upload a photo of yourself");
    if (isIdDoc) setModalTitle("Upload your ID");
  }, [isIdDoc, isSelfie]);

  const handleClose = useCallback(() => {
    if (!fileUploadSubmitting) {
      reset();
      setUploadError("");
      closeModal();
    }
  }, [closeModal, reset, fileUploadSubmitting]);

  const IdContent = () => (
    <div className="upload-modal__content-inner">
      <p>Upload a valid government-issued ID (not a school ID) with a photo for identity verification.</p>
      <p>
        If you take a picture of your ID using a mobile phone, ensure it is readable before uploading. If your ID is not
        in English, use your passport. Your ID will be verified by the Nexford team within 48 hours.
      </p>
      <img src={idExample} alt="Example of ID photo" />
    </div>
  );

  const SelfieContent = () => (
    <div className="upload-modal__content-inner">
      <p>
        Upload a passport-style portrait photo. It can be a selfie, but must be head and shoulders with your face
        visible.
      </p>
      <p>
        Your photo will be reviewed by the Nexford team within 48 hours. Once approved, it will be used to verify your
        identity throughout your enrollment.
      </p>
      <img src={selfieExample} alt="Example of ID photo" />
    </div>
  );

  // Validate file types for photo upload
  // Edge case validation - should be caught earlier by the field validation
  const isPhotoValid = (file: File) => {
    if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
      setError("selectedFile", {
        type: "filetype",
        message: "You are uploading the wrong file type. Only .jpg or .png files are accepted.",
      });
      return false;
    }
    return true;
  };

  const isLatinCharactersOnly = (value: string) => {
    // eslint-disable-next-line no-control-regex
    const regex = /^([A-Za-z\u0000-\u00ff\s]*)$/gi;
    return regex.test(value);
  };

  const getFileExtension = (file: File) => file.name.substring(file.name.lastIndexOf(".") + 1);

  // After uploading the file, save the document name to the learner api
  const saveFileAfterUpload = async (fileUploadResp: { tempFileId: string }, originalFileName: string) => {
    if (fileUploadResp && originalFileName && uploadType) {
      const uploadedFileName = fileUploadResp.tempFileId;
      documentSave.mutate(
        {
          payload: { uploadedFileName, originalFileName },
          documentType: uploadType,
          msalInstance: instance,
        },
        {
          onSuccess() {
            setFileUploadSubmitting(false);
            setUploadSuccessful(true);
            handleClose();
          },
          onError(e) {
            const error = e as HttpError;
            setFileUploadSubmitting(false);
            setUploadError(error.message);
          },
        },
      );
    }
  };

  // After uploading the file, save the document name to the learner api
  const saveGovermentIdAfterUpload = async (
    fileUploadResp: { tempFileId: string },
    originalFileName: string,
    officialName: string,
  ) => {
    if (fileUploadResp && originalFileName && uploadType) {
      const uploadedFileName = fileUploadResp.tempFileId;
      govIdDataSave.mutate(
        {
          payload: { uploadedFileName, originalFileName, officialName },
          documentType: uploadType,
          msalInstance: instance,
        },
        {
          onSuccess() {
            setFileUploadSubmitting(false);
            setUploadSuccessful(true);
            handleClose();
          },
          onError(e) {
            const error = e as HttpError;
            setFileUploadSubmitting(false);
            setUploadError(error.message);
          },
        },
      );
    }
  };

  // Upload the file to the api
  const submitForm: SubmitHandler<UploadFormInputs> = async (data) => {
    const selectedFile = data.selectedFile && data.selectedFile[0];
    const officialName = data.officialName || "";
    const isFileValid = selectedFile && isPhotoValid(selectedFile);
    if (isFileValid && uploadType && (!isIdDoc || (isIdDoc && officialName))) {
      setFileUploadSubmitting(true);
      const uploadFileType = getFileExtension(selectedFile);

      fileUploadService.mutate(
        {
          payload: { file: selectedFile },
          fileExtension: uploadFileType,
          msalInstance: instance,
        },
        {
          onSuccess(response: { tempFileId: string }) {
            if (isIdDoc) {
              saveGovermentIdAfterUpload(response, selectedFile.name, officialName);
            } else {
              saveFileAfterUpload(response, selectedFile.name);
            }
          },
          onError(e) {
            const error = e as HttpError;
            setFileUploadSubmitting(false);
            setUploadError(error.message);
          },
        },
      );
    }
  };

  return (
    <IonModal data-testid="upload-modal" isOpen={isOpen} onDidDismiss={handleClose} className="nxu-modal upload-modal">
      <IonHeader className="upload-modal__header">
        <IonToolbar>
          <IonTitle data-testid="upload-modal-title">{modalTitle}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              fill="outline"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="upload-modal__content">
        <div>
          {isSelfie && <SelfieContent />}
          {isIdDoc && <IdContent />}
        </div>
        <div className="upload-modal__content-inner">
          <form className="upload-modal__form" onSubmit={handleSubmit(submitForm)}>
            {isIdDoc && (
              <div
                className={cx(
                  "upload-modal__field-wrapper",
                  errors.officialName && "upload-modal__field-wrapper--error",
                )}
              >
                <IonInput
                  data-testid="official-name-input"
                  fill="outline"
                  type="text"
                  placeholder="Please provide your full legal name"
                  {...register("officialName", {
                    required: { value: isIdDoc, message: "Full legal name is required" },
                    maxLength: { value: 200, message: "Full legal name must be less than 200 characters" },
                    validate: {
                      notEmpty: (value) => (value && value.trim().length > 0) || "Full legal name is required",
                      match: (value) =>
                        (value && isLatinCharactersOnly(value)) ||
                        "Full legal name using only Latin characters is required",
                    },
                  })}
                />
                {errors.officialName && <IonNote color="danger">{errors.officialName?.message}</IonNote>}
              </div>
            )}
            <div
              className={cx("upload-modal__field-wrapper", errors.selectedFile && "upload-modal__field-wrapper--error")}
            >
              <div>
                <input
                  data-testid="file-upload"
                  {...register("selectedFile", {
                    required: true,
                    validate: {
                      lessThan10MB: (files) =>
                        (files && files[0]?.size < 10485760) ||
                        "File size is larger than allowed size. The file must be less than 10 MB",
                    },
                  })}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                />
                {isDirty && (
                  <IonButton
                    size="small"
                    shape="round"
                    color="dark"
                    fill="clear"
                    aria-label="Clear the uploaded file"
                    onClick={() => resetField("selectedFile")}
                  >
                    <IonIcon slot="icon-only" icon={closeCircleOutline}></IonIcon>
                  </IonButton>
                )}
              </div>
              {errors.selectedFile && <IonNote color="danger">{errors.selectedFile?.message}</IonNote>}
              {uploadError && <NxuAlert message={uploadError} />}
            </div>
            <NxuPrimaryButton
              disabled={!isDirty || fileUploadSubmitting}
              className="upload-modal__form-submit"
              type="submit"
            >
              Upload
            </NxuPrimaryButton>
          </form>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default UploadModal;
