import React from "react";
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";

import { useInitialisationContext } from "utils/context/Initialisation";
import { LocalRoutes } from "constants/routes";

import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import { PageContent } from "components/molecule/page-wrap/page-wrap";

import "./success.scss";

/**
 * Success screen shown on enrollment
 */
export default function SuccessPage() {
  const { agreementAcceptance } = useInitialisationContext();
  const { instance } = useMsal();

  if (!agreementAcceptance) window.location.replace(LocalRoutes.HOME);

  return (
    <PageContent className="success-wrapper">
      {agreementAcceptance === "accepted" && (
        <CardPanel className="success-wrapper__inner">
          <h4>Welcome back to Nexford University</h4>
          <p>
            Congratulations! You’re now ready to start your next learning experience at Nexford. Anything is possible.
          </p>
          <div className="success-icon" />
          <p>Soon, you’ll get an email from our success team about how to get started.</p>
        </CardPanel>
      )}

      {agreementAcceptance === "rejected" && (
        <CardPanel className="success-wrapper__inner">
          <p>You've rejected your agreement at Nexford University. If you'd still like to enroll, get in touch</p>
        </CardPanel>
      )}

      {agreementAcceptance === "accepted" && (
        <Link to={LocalRoutes.HOME}>
          <NxuPrimaryButton size="large" expand="block">
            Return to home
          </NxuPrimaryButton>
        </Link>
      )}
      {agreementAcceptance === "rejected" && (
        <NxuPrimaryButton size="large" expand="block" onClick={() => instance.logoutRedirect()}>
          Log out
        </NxuPrimaryButton>
      )}
    </PageContent>
  );
}
