import React, { useEffect, useState } from "react";
import { IonInput, IonIcon, IonText } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { CurrencyProvider, TuitionRequest } from "types/wallet";
import { formatDate } from "utils/format-date.utils";
import { NxuComponentLoading } from "@nexford/nexford-ui-component-library";
import SecondaryButton from "components/atom/button-secondary/button-secondary";
import CardPanel from "components/atom/card-panel";

import "./payment-info.scss";

// Payment Form validation schema
const paymentAmountFormSchema = yup.object({
  amount: yup
    .number()
    .typeError(
      "Invalid payment amount. Please enter a number, without any special characters such as currency symbols.",
    )
    .min(0.01, "Amount must be greater than 0")
    .required(),
});

export interface PaymentInfoProps {
  tuitionData: TuitionRequest;
  updateTotalCost: (newAmount?: number) => void;
  presetAmount?: number;
  providersList?: Array<CurrencyProvider>;
  clearProvidersList: () => void;
  providersRequestLoading: boolean;
  providersRequestError?: string;
  paymentSubmitting?: boolean;
}

/**
 * Form component to set the amount to be paid
 */
const PaymentInfo = (props: PaymentInfoProps) => {
  const {
    tuitionData,
    updateTotalCost,
    presetAmount,
    providersList,
    providersRequestLoading,
    providersRequestError,
    clearProvidersList,
    paymentSubmitting,
  } = props;

  const [allowProvidersRequest, setAllowProvidersRequest] = useState(false);

  const nonUsdCurrencies = providersList?.find((e) => e.CurrencyCode !== "USD" && e.Amount);

  const nonUsdAmount = (amount: number, currency: string) => {
    const readableAmount = parseFloat(amount.toFixed(2)).toLocaleString("en-us");
    if (currency === "NGN") return `${String.fromCharCode(8358)}${readableAmount}`;
    return `${readableAmount} ${currency}`;
  };

  const newPaymentAmountForm = useForm({
    defaultValues: {
      amount: presetAmount || 0,
    },
    resolver: yupResolver(paymentAmountFormSchema),
  });
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = newPaymentAmountForm;

  const earlyInvoiceDate = tuitionData.nextBillDate ? new Date(tuitionData.nextBillDate) : null;
  if (earlyInvoiceDate) {
    const timezoneOffset = new Date().getTimezoneOffset();
    earlyInvoiceDate.setHours(earlyInvoiceDate.getHours() - timezoneOffset / 60);
  }
  const startDateFormatted = earlyInvoiceDate ? formatDate(earlyInvoiceDate) : null;
  const dueDateFormatted = tuitionData.invoices[0] ? formatDate(tuitionData.invoices[0].dueDate) : null;
  const amountFormatted = (balance: number) => (balance < 0 ? `-$${Math.abs(balance)}` : `$${balance}`);

  // Preset amount is defined on selecting an individual invoice
  // Set the total cost from preset and request the payment providers
  useEffect(() => {
    if (presetAmount) {
      updateTotalCost(presetAmount);
      setValue("amount", presetAmount);
    }
  }, [presetAmount, updateTotalCost, setValue]);

  // Successfully populated the providers list, user can't repeat the request without changing the amount
  useEffect(() => {
    if (providersList?.length) setAllowProvidersRequest(false);
  }, [providersList, setAllowProvidersRequest]);

  // Error occured on getting the providers list, user can repeat the request
  useEffect(() => {
    if (providersRequestError) setAllowProvidersRequest(true);
  }, [providersRequestError, setAllowProvidersRequest]);

  // Payment value is set to state, send a request to payment provider options
  const handleSetPaymentAmount: SubmitHandler<{ amount: number }> = async (formValues) => {
    updateTotalCost(formValues.amount);
  };

  // Payment value has been changed but not submitted
  const handlePaymentAmountChange = () => {
    setAllowProvidersRequest(true);
    clearProvidersList();
  };

  return (
    <section>
      <CardPanel className="payment-info__wrapper">
        {tuitionData.totalAmountDue <= 0 && (
          <div className="payment-info__row">
            <div className="payment-info__col payment-info__balance" data-testid="payment-wallet-balance">
              <span>Wallet balance</span>
              <span>{amountFormatted(tuitionData.balance)}</span>
            </div>
            <div className="payment-info__col payment-info__date" data-testid="payment-next-invoice-date">
              {startDateFormatted && (
                <>
                  <span>Next invoice available on</span>
                  <span>{startDateFormatted}</span>
                </>
              )}
            </div>
          </div>
        )}

        {tuitionData.totalAmountDue > 0 && (
          <div className="payment-info__row">
            <div className="payment-info__col payment-info__balance" data-testid="payment-balance-due">
              <span>Total amount due</span>
              <span>{amountFormatted(tuitionData.totalAmountDue)}</span>
            </div>
            <div className="payment-info__col payment-info__date" data-testid="payment-due-date">
              {dueDateFormatted && (
                <>
                  <span>Payment due on</span>
                  <span>{dueDateFormatted}</span>
                </>
              )}
            </div>
          </div>
        )}
      </CardPanel>

      <CardPanel className="payment-info__wrapper payment-info__form" testId="payment-info__form">
        <form onSubmit={handleSubmit(handleSetPaymentAmount)}>
          <div className="payment-info__row">
            <div className="payment-info__col">
              <Controller
                control={control}
                name="amount"
                render={({ field, fieldState }) => (
                  <div className="payment-info__field-item" data-testId="amount-to-pay-textbox">
                    <span>$</span>
                    <IonInput
                      label="Enter amount to pay (USD)"
                      labelPlacement="stacked"
                      placeholder="ex. $150.00"
                      fill="outline"
                      type="number"
                      step="any"
                      value={field.value || null}
                      errorText={fieldState.error?.message}
                      className={fieldState.error ? "ion-touched ion-invalid" : ""}
                      onIonInput={(e) => {
                        field.onChange(e);
                        handlePaymentAmountChange();
                      }}
                      onIonBlur={field.onBlur}
                      disabled={paymentSubmitting || providersRequestLoading}
                    />
                  </div>
                )}
              />
            </div>
            {allowProvidersRequest && (
              <div className="payment-info__col">
                <SecondaryButton type="submit" disabled={paymentSubmitting || providersRequestLoading}>
                  Confirm Amount
                </SecondaryButton>
              </div>
            )}
            {!!nonUsdCurrencies && (
              <div className="payment-info__col payment-info__col--exchange-rate">
                <span>{nonUsdAmount(nonUsdCurrencies.Amount, nonUsdCurrencies.CurrencyCode)}</span>
              </div>
            )}
          </div>
          {!!nonUsdCurrencies && (
            <div className="payment-info__row payment-info__row--exchange-rate">
              <span>{`1 USD = ${nonUsdCurrencies.ExchangeRate} ${nonUsdCurrencies.CurrencyCode}`}</span>
            </div>
          )}
        </form>
      </CardPanel>

      {!providersList?.length && (
        <CardPanel className="payment-info__set-amount" centre testId={"payment-info-set-amount"}>
          {providersRequestLoading ? (
            <NxuComponentLoading />
          ) : (
            <>
              <IonIcon aria-label="Info" icon={alertCircleOutline} size="large" color="danger" />
              <IonText color="danger">
                <p>Set and confirm the amount to be paid to get your payment options</p>
              </IonText>
            </>
          )}
        </CardPanel>
      )}
    </section>
  );
};

export default PaymentInfo;
