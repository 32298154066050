import React from "react";
import { IonIcon } from "@ionic/react";
import { downloadOutline } from "ionicons/icons";

import "./file-download-button.scss";

export interface FileDownloadButtonProps {
  downloadEvent: () => void;
  ariaLabel?: string;
}

/**
 * Re-usable download button component
 */
const FileDownloadButton = ({ downloadEvent, ariaLabel }: FileDownloadButtonProps) => (
  <button
    className="file-download-button"
    type="button"
    aria-label={ariaLabel || "Download File"}
    onClick={downloadEvent}
  >
    <IonIcon icon={downloadOutline} />
  </button>
);

export default FileDownloadButton;
