import { useQuery } from "@tanstack/react-query";

import { IPublicClientApplication } from "@azure/msal-browser";

import { LearningPathList } from "types/learning-path";
import { apiGet } from "./api-client";

export const programErrMsg = {
  fetchCoursera:
    "Uh-oh! There was an error on getting your Coursera link. Please refresh and try again or contact support.",
};

const fetchLearnerPath = async (
  msalInstance: IPublicClientApplication,
  programEnrollmentId?: string,
): Promise<LearningPathList> => {
  const response = apiGet(
    `api/mynxuaggregator/self/program-enrollments/${programEnrollmentId}/course-list`,
    "",
    msalInstance,
  );
  return response;
};

const useLearnerPath = (
  isNexfordLearner: boolean,
  msalInstance: IPublicClientApplication,
  programEnrollmentId?: string,
) =>
  useQuery({
    queryKey: ["learner-path"],
    queryFn: () => fetchLearnerPath(msalInstance, programEnrollmentId),
    throwOnError: false,
    enabled: isNexfordLearner,
    refetchOnWindowFocus: false,
    staleTime: 600000,
  });

const fetchCourseraProgram = async (
  msalInstance: IPublicClientApplication,
  productType: string,
  productCode: string,
): Promise<string> => {
  const response = apiGet(`api/coursera/${productType}/${productCode}/link`, programErrMsg.fetchCoursera, msalInstance);
  return response;
};

const useCourseraProgram = (
  courseraProgramStarted: boolean,
  msalInstance: IPublicClientApplication,
  productType: string,
  productCode: string,
) =>
  useQuery({
    queryKey: ["coursera-program", productType, productCode],
    queryFn: () => fetchCourseraProgram(msalInstance, productType, productCode),
    throwOnError: false,
    enabled: courseraProgramStarted,
    refetchOnWindowFocus: false,
    staleTime: 600000,
  });

export { useLearnerPath, useCourseraProgram };
