import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { IonButton, IonIcon } from "@ionic/react";
import {
  cardOutline,
  logoPaypal,
  radioButtonOffOutline,
  checkmarkCircleOutline,
  businessOutline,
} from "ionicons/icons";

import { ReactComponent as FlywireLogo } from "assets/img/flywire.svg";
import { ReactComponent as FlutterwaveLogo } from "assets/img/flutterwave.svg";

import {
  FlutterwaveConfig,
  FlywireCallback,
  CurrencyProvider,
  ProviderTypes,
  PPO,
  FlywireConfig,
  FlutterwaveCallback,
  EdubancConfig,
} from "types/wallet";
import { AggregateLearnerProfile } from "types/learner";

import { NxuAlert } from "@nexford/nexford-ui-component-library";
import CardPanel, { SuccessPanel } from "components/atom/card-panel";
import GetInTouch from "components/atom/get-in-touch";

import ProviderModal from "../provider-modal";
import Flutterwave from "./flutterwave";
import Flywire from "./flywire";
import StripePaymentForm from "../stripe-payment-form";

import "./payment-provider.scss";
import PaypalForm from "./paypal";

const { FLYWIRE, FLUTTERWAVE, EDUBANC, STRIPE, PAYPAL, TRANSFER } = PPO;

export interface PaymentProviderProps {
  onSubmitFlywire: (data: FlywireCallback) => void;
  onSubmitFlutterwave: (data: FlutterwaveCallback) => void;
  onSubmitPaypal: () => void;
  handleStripePayment: (paymentMethodId: string, saveCard?: boolean) => void;
  handleStripePaymentCreate: (inProgress: boolean) => void;
  currencies: CurrencyProvider[];
  paymentSubmitting: boolean;
  flywireConfig?: FlywireConfig;
  flutterwaveConfig?: FlutterwaveConfig;
  edubancConfig?: EdubancConfig;
  applicantPersonalData: AggregateLearnerProfile;
  learnerId: string;
  callbackId?: string;
  paymentError?: string;
  fullReset: () => void;
  paymentSuccess?: string;
  currentInvoiceId?: string | null;
}

interface ProviderItem {
  Provider: ProviderTypes;
  ProviderAmount?: number;
  CurrencyCode?: string;
}

/**
 * Allow a user to select which payment provider they want to use
 */
const PaymentProvider = ({
  currencies,
  paymentSubmitting,
  onSubmitFlywire,
  onSubmitFlutterwave,
  onSubmitPaypal,
  handleStripePayment,
  handleStripePaymentCreate,
  flywireConfig,
  flutterwaveConfig,
  edubancConfig,
  applicantPersonalData,
  learnerId,
  callbackId,
  paymentError,
  fullReset,
  paymentSuccess,
  currentInvoiceId,
}: PaymentProviderProps) => {
  const [currencyProvidersList, setCurrencyProvidersList] = useState<Array<ProviderItem>>();

  const [defaultProvidersList, setDefaultProvidersList] = useState<Array<ProviderItem>>();

  const [currentProvider, setCurrentProvider] = useState<ProviderTypes | null>(null);
  const [currentProviderIndex, setCurrentProviderIndex] = useState<number | null>(null);
  const [paymentIsOpen, setPaymentIsOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  // Add a ref with a scroll event to scroll down to the form once rendered
  const paymentWrapperRef = useRef<HTMLDivElement>(null);
  const scrollToElement = () => {
    setTimeout(() => {
      paymentWrapperRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  useEffect(() => {
    if (
      currentProvider === STRIPE ||
      currentProvider === FLYWIRE ||
      currentProvider === FLUTTERWAVE ||
      currentProvider === EDUBANC
    )
      scrollToElement();
  }, [currentProvider]);

  // The currencies list returned by the API should be extended with out additional payment providers
  const generateFullProvidersList = useCallback(() => {
    let defaultList: Array<ProviderItem> = [];
    const edubancCurrency = currencies.find((x) => x.Provider.toLowerCase() === EDUBANC);

    if (edubancConfig && !!edubancCurrency) {
      // User is an edubanc learner and we only want to offer them a single payment option
      defaultList.push({
        Provider: edubancCurrency.Provider,
        CurrencyCode: edubancCurrency.CurrencyCode,
        ProviderAmount: edubancCurrency.Amount,
      });
    } else {
      currencies.forEach((c) => {
        defaultList.push({ Provider: c.Provider, CurrencyCode: c.CurrencyCode, ProviderAmount: c.Amount });
      });
      defaultList = [...defaultList];
    }

    return defaultList;
  }, [currencies, edubancConfig]);

  useEffect(() => {
    const providersListResult = generateFullProvidersList();
    setCurrencyProvidersList(providersListResult);
    // Give the learner the option to pay by bank transfer if they're not Edubanc and not NGN
    if (!currencies.find((x) => x.Provider.toLowerCase() === EDUBANC)) {
      if (currencies.find((x) => x.CurrencyCode !== "NGN")) {
        setDefaultProvidersList([{ Provider: TRANSFER }]);
      }
    }
  }, [currencies, generateFullProvidersList]);

  useEffect(() => {
    if (paymentError) {
      setPaymentIsOpen(false);
    }
  }, [paymentError]);

  // Open the form or 3rd party plugin for the selected payment
  const togglePaymentState = useCallback(
    (provider: ProviderTypes | null) => {
      fullReset();
      setPaymentIsOpen(!!provider);
    },
    [fullReset],
  );

  const closePaymentModal = () => {
    togglePaymentState(null);
    setIsPaymentModalOpen(false);
    setCurrentProvider(null);
    setCurrentProviderIndex(null);
  };

  // Allow the user to select the payment type they want to use
  const selectPaymentProvider = useCallback(
    (provider: ProviderTypes, index: number) => {
      if (paymentSubmitting) return;

      setCurrentProvider(provider);
      setCurrentProviderIndex(index);
      fullReset();

      // Excluding Flywire & Flutterwave, progress to the payment stage
      switch (provider) {
        case STRIPE: {
          setIsPaymentModalOpen(false);
          togglePaymentState(null);
          return;
        }
        case TRANSFER: {
          setIsPaymentModalOpen(true);
          togglePaymentState(provider);
          return;
        }
        default: {
          setIsPaymentModalOpen(false);
          togglePaymentState(null);
        }
      }
    },
    [fullReset, paymentSubmitting, togglePaymentState],
  );

  // Create a payment button for Flutterwae and Flywire
  const generatePaymentButton = () => {
    if (!currentProvider || currentProviderIndex === null) return null;
    if (currentProvider === FLYWIRE && flywireConfig) {
      return (
        <CardPanel className="payment-provider__action">
          <Flywire
            paymentIsOpen={paymentIsOpen}
            onOpenFlywire={() => togglePaymentState(FLYWIRE)}
            onCancel={() => togglePaymentState(null)}
            onSubmit={onSubmitFlywire}
            submitting={paymentSubmitting}
            config={flywireConfig}
            key={currentProviderIndex}
          />
        </CardPanel>
      );
    }

    if (currentProvider.toLowerCase() === FLUTTERWAVE && flutterwaveConfig) {
      return (
        <CardPanel className="payment-provider__action">
          <Flutterwave
            onOpenFlutterwave={() => togglePaymentState(FLUTTERWAVE)}
            onCancel={() => {
              togglePaymentState(null);
            }}
            onSubmit={onSubmitFlutterwave}
            paymentIsOpen={paymentIsOpen}
            submitting={paymentSubmitting}
            flutterwaveConfig={flutterwaveConfig}
            reference="INV"
            initiator="campusWallet"
            learnerId={learnerId}
            currencyProvider={currencies[currentProviderIndex]}
            applicantPersonalData={applicantPersonalData}
            key={currentProviderIndex}
            currentInvoiceId={currentInvoiceId || ""}
          />
        </CardPanel>
      );
    }

    if (currentProvider.toLowerCase() === EDUBANC && edubancConfig) {
      return (
        <CardPanel className="payment-provider__action">
          <Flutterwave
            onOpenFlutterwave={() => togglePaymentState(EDUBANC)}
            onCancel={() => {
              togglePaymentState(null);
            }}
            onSubmit={onSubmitFlutterwave}
            paymentIsOpen={paymentIsOpen}
            submitting={paymentSubmitting}
            flutterwaveConfig={edubancConfig}
            reference="INV"
            initiator="campusWallet"
            learnerId={learnerId}
            currencyProvider={currencies[currentProviderIndex]}
            applicantPersonalData={applicantPersonalData}
            key={currentProviderIndex}
            currentInvoiceId={currentInvoiceId || ""}
          />
        </CardPanel>
      );
    }

    if (currentProvider === PAYPAL && currencyProvidersList && currencyProvidersList[currentProviderIndex]) {
      const matchedCurrency = currencies.find((item) => item.Provider === PPO.PAYPAL);
      if (!matchedCurrency) return null;

      return (
        <CardPanel className="payment-provider__action">
          <PaypalForm
            onSubmit={onSubmitPaypal}
            onCancel={() => setPaymentIsOpen(false)}
            callbackId={callbackId || ""}
            paypalProvider={matchedCurrency}
            submitting={paymentSubmitting}
          />
        </CardPanel>
      );
    }

    return null;
  };

  const getProviderIcon = (provider: ProviderTypes) => {
    switch (provider.toLowerCase()) {
      case STRIPE: {
        return <IonIcon icon={cardOutline} />;
      }
      case PAYPAL: {
        return <IonIcon icon={logoPaypal} />;
      }
      case TRANSFER: {
        return <IonIcon icon={businessOutline} />;
      }
      case FLYWIRE: {
        return <FlywireLogo />;
      }
      case FLUTTERWAVE: {
        return <FlutterwaveLogo />;
      }
      case EDUBANC: {
        return <FlutterwaveLogo />;
      }
      default: {
        return <IonIcon icon={cardOutline} />;
      }
    }
  };

  const generateFlutterwaveLabel = useCallback((currencyCode?: string) => {
    switch (currencyCode) {
      case "EGP":
        return `Pay in ${currencyCode} - Google Pay / Card`;
      case "ETB":
        return `Pay in ${currencyCode} - Card`;
      case "GHS":
        return `Pay in ${currencyCode} - Mobile Money / Card`;
      case "KES":
        return `Pay in ${currencyCode} - M-Pesa / Card`;
      case "NGN":
        return `Pay in ${currencyCode} - Bank Transfer / Card`;
      case "RWF":
        return `Pay in ${currencyCode} - Mobile Money / Card`;
      case "TZS":
        return `Pay in ${currencyCode} - Mobile Money / Card`;
      case "UGX":
        return `Pay in ${currencyCode} - Mobile Money / Card`;
      case "ZAR":
        return `Pay in ${currencyCode} - Mobile Money / Card`;
      default:
        return `Pay in ${currencyCode} - Flutterwave`;
    }
  }, []);

  const generateProviderSelectionButton = useCallback(
    (p: ProviderItem, i: number) => {
      let readableProvider: string = p.Provider;

      if (p.Provider === STRIPE) readableProvider = "Pay in USD - Debit/Credit Card";
      if (p.Provider === TRANSFER) readableProvider = "Bank Transfer";
      if (p.Provider === PAYPAL) readableProvider = "Pay in USD - PayPal";
      if (p.Provider.toLowerCase() === FLYWIRE) readableProvider = `Pay in Local Currency - Flywire`;
      if (p.Provider.toLowerCase() === FLUTTERWAVE) readableProvider = generateFlutterwaveLabel(p.CurrencyCode);
      if (p.Provider.toLowerCase() === EDUBANC) readableProvider = `Pay in ${p.CurrencyCode} - Edubanc`;

      return (
        <IonButton
          fill="outline"
          className={cx("payment-provider__item", currentProvider === p.Provider ? "selected" : "")}
          key={`payment-provider__item-${i}`}
          onClick={() => {
            selectPaymentProvider(p.Provider, i);
          }}
          disabled={paymentSubmitting || paymentIsOpen}
        >
          <div className="logo">{getProviderIcon(p.Provider)}</div>
          <div className="text">{readableProvider}</div>
          <div className="check-icon">
            {currentProvider === p.Provider ? (
              <IonIcon size="default" icon={checkmarkCircleOutline} />
            ) : (
              <IonIcon icon={radioButtonOffOutline} />
            )}
          </div>
        </IonButton>
      );
    },
    [currentProvider, paymentIsOpen, paymentSubmitting, selectPaymentProvider],
  );

  // Memoise the list of Provider Selection buttons generated from the providers list
  // These buttons will allow the user to select the provider they want to use
  const generateProviderSelectionButtons = useMemo(() => {
    if (currencies.length && !currencyProvidersList?.length) return <></>;

    if (!currencyProvidersList?.length) return <NxuAlert message="No available payment providers" />;

    return (
      <CardPanel className="payment-provider__list" testId={"payment-provider-list"}>
        <h3>Recommended</h3>
        {currencyProvidersList.map((p, i) => generateProviderSelectionButton(p, i))}
        {defaultProvidersList?.length && <h3>Other</h3>}
        {defaultProvidersList?.map((p, i) => generateProviderSelectionButton(p, i))}
      </CardPanel>
    );
  }, [currencies.length, currencyProvidersList, defaultProvidersList, generateProviderSelectionButton]);

  if (!currencies) {
    return <></>;
  }

  if (paymentSuccess) {
    return (
      <SuccessPanel testId="payment-success">
        <p>{paymentSuccess}</p>
      </SuccessPanel>
    );
  }

  return (
    <div className="payment-provider__wrapper">
      {generateProviderSelectionButtons}
      <div ref={paymentWrapperRef}>
        {currentProvider !== STRIPE && !!paymentError && (
          <>
            <NxuAlert message={paymentError} />
          </>
        )}
        {generatePaymentButton()}
        {currentProvider === STRIPE && (
          <StripePaymentForm
            onPaymentMethodCreated={handleStripePayment}
            handleStripePaymentCreate={handleStripePaymentCreate}
            submitting={paymentSubmitting}
            error={paymentError}
            paymentSuccess={paymentSuccess}
            fullReset={fullReset}
            enableSaveCard={false}
          />
        )}
      </div>
      <GetInTouch type="billings" />
      <ProviderModal
        learnerId={learnerId}
        type={currentProvider as string}
        isOpen={isPaymentModalOpen}
        closeModal={closePaymentModal}
      />
    </div>
  );
};

export default PaymentProvider;
