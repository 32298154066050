import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";

import { LocalRoutes } from "constants/routes";

import msalConfig from "config";

export interface AuthConfig {
  readonly tenantId: string;
  readonly clientId: string;
  readonly campusApiScope: string;
}

export interface AuthContextType {
  userName: string;
  userAccount: AccountInfo | null;
}

export interface AuthProviderProps {
  children?: React.ReactNode;
}

// Pages that we should redirect a signed-in learner away from
export const anonymousPagesToRedirect = [LocalRoutes.LOGIN, LocalRoutes.LOGOUT];
// Pages that can be accessed without login
export const anonymousPages = [...anonymousPagesToRedirect, LocalRoutes.MAINTENANCE];

export const AuthContext = React.createContext<any>({} as any);

/**
 * Get and Store the account details from MSAL and handle our custom interactions with MSAL instance
 */
export function AuthProvider(props: AuthProviderProps) {
  const { children } = props;
  const authConfig: AuthConfig = msalConfig.authentication;

  const { instance, accounts } = useMsal();
  const [userName, setUserName] = useState<string>();
  const [userAccount, setUserAccount] = useState<AccountInfo | null>();

  const getBearerToken = useCallback(async () => {
    if (accounts.length === 0) {
      instance.acquireTokenRedirect({ scopes: [authConfig.campusApiScope] });
    }

    const request = {
      scopes: [authConfig.campusApiScope],
      account: accounts[0],
    };
    const accessToken = await instance
      .acquireTokenSilent(request)
      .then((result) => {
        localStorage.setItem("msalToken", result.accessToken);
        if (accounts.length) {
          setUserName(accounts[0].name);
          setUserAccount(accounts[0]);
        }
        return result.accessToken;
      })
      .catch(async () => {
        // Account exists but acquire token failed,
        // Means they signed in previously but signin is now invalid,
        await instance.acquireTokenRedirect(request);
      });

    return accessToken;
  }, [accounts, authConfig.campusApiScope, instance]);

  useEffect(() => {
    getBearerToken();
  }, [accounts, getBearerToken, instance]);

  const value = {
    userName,
    setUserName,
    userAccount,
    setUserAccount,
    getBearerToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => useContext(AuthContext);
