import React from "react";
import { IonFabButton, IonIcon } from "@ionic/react";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";

import { downloadFile } from "utils/blob.utils";
import FileDownloadButton from "components/atom/file-download-button";

import "./agreement-doc-header.scss";

export interface AgreementDocHeaderProps {
  welcomeMsg: string;
  hasFile: boolean;
  file: string;
  fileTitle: string;
  hasToggle: boolean;
  isEnrollmentOpen: boolean;
  toggleEvent: () => void;
}

/**
 * Header component to be displayed above the SAP Plan and the agreement Doc
 */
const AgreementDocHeader = (props: AgreementDocHeaderProps) => {
  const { welcomeMsg, hasFile, file, fileTitle, hasToggle, isEnrollmentOpen, toggleEvent } = props;
  return (
    <div className="agreement__doc-header">
      <h1 className="agreement__welcome">{welcomeMsg}</h1>
      <div>
        {hasToggle && (
          <IonFabButton size="small" color="light" onClick={() => toggleEvent()}>
            <IonIcon
              aria-label={isEnrollmentOpen ? "Switch to SAP plan" : "Switch to the agreement"}
              icon={isEnrollmentOpen ? chevronBackOutline : chevronForwardOutline}
            />
          </IonFabButton>
        )}
        {hasFile && <FileDownloadButton downloadEvent={() => downloadFile(file, fileTitle)} />}
      </div>
    </div>
  );
};

export default AgreementDocHeader;
