import { PaymentMethodCreateParams } from "@stripe/stripe-js";

import { CardholderDetails } from "types/wallet";

// Convert card form values to a format that can be used by the Stripe API
export function mapStripePaymentData(cardholderDetails: CardholderDetails): PaymentMethodCreateParams.BillingDetails {
  return {
    name: `${cardholderDetails.FirstName} ${cardholderDetails.LastName}`,
    address: {
      line1: cardholderDetails.Street,
      line2: "",
      city: cardholderDetails.City,
      state: "",
      postal_code: cardholderDetails.PostalCode,
    },
    email: cardholderDetails.Email,
  };
}

// Convert Stripe Payment resuts
export enum StripeResponeErrorCode {
  applicationStageIsInvalid = "ApplicationStageIsInvalid",
  feeAlreadyExists = "FeeAlreadyExists",
  invalidCouponCode = "InvalidCouponCode",
  invalidPaymentDetails = "InvalidPaymentDetails",
  parameterIsRequired = "ParameterIsRequired",
  parameterIsInvalid = "ParameterIsInvalid",
  expectedAmountMismatch = "ExpectedAmountMismatch",
}
// #7360 - expand this out when working on the additional stripe card field validation
export function mapStripeErrorCode(errorCode: string): { error: string; disableRetry: boolean; errorCode: string } {
  switch (errorCode) {
    case StripeResponeErrorCode.applicationStageIsInvalid: {
      return { error: `System busy. Please try again. Error: ${errorCode}`, disableRetry: false, errorCode };
    }
    case StripeResponeErrorCode.feeAlreadyExists: {
      return {
        error: `Uh-oh! There was an error on charging this fee. Please contact support to confirm the status of the payment. Error: ${errorCode}`,
        disableRetry: true,
        errorCode,
      };
    }
    case StripeResponeErrorCode.expectedAmountMismatch: {
      return {
        error: `Payment amount has been recalculated, please refresh and try again Error: ${errorCode}`,
        disableRetry: false,
        errorCode,
      };
    }
    default: {
      return {
        error: `Uh-oh! There was an error on charging this fee. Please contact support to confirm the status of the payment. Error: ${errorCode}`,
        disableRetry: true,
        errorCode,
      };
    }
  }
}
