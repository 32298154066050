import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import cx from "classnames";
import { useMsal } from "@azure/msal-react";

import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { IonFabButton, IonIcon } from "@ionic/react";
import { chevronBack } from "ionicons/icons";

import { useStripeContext } from "utils/context/Stripe";
import { useAuthContext } from "utils/context/Auth";
import {
  useProvidersOptions,
  useTuitionInfo,
  usePayApplicationFeeFlutterwave,
  useApplicationFeeStripe,
  walletErrMsg,
} from "utils/hooks/wallet";
import { useLearner } from "utils/hooks/learners";
import { HttpError } from "utils/errors/HttpError";
import { mapStripeErrorCode } from "utils/payment-utils";
import { LocalRoutes } from "constants/routes";

import {
  FlutterwaveCallback,
  CurrencyProvider,
  FlutterwaveConfig,
  FlywireConfig,
  FlywireCallback,
  EdubancConfig,
  FlutterwaveApplicationFeePayload,
  StripeApplicationFeePayload,
} from "types/wallet";

import { NxuAlert, NxuContentLoading } from "@nexford/nexford-ui-component-library";
import GetInTouch from "components/atom/get-in-touch";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import PaymentInfo from "./payment-info";
import PaymentProvider from "./payment-provider";

const CHARGED = "Charged";
const REQUIRES_AUTHENTICATION = "RequiresAuthentication";

const Copy = {
  flywireErrMsg:
    "We were unable to complete the payment through Flywire. If this was in error, please try again or contact support.",
  paymentAuthenticationErrorMessage:
    "Uh-oh! There was an error on authenticating your card payment. Please try again or contact support.",
  paymentDefaultSuccessMessage:
    "Success! Your payment has been completed. Payments can take 24-48 hours to be reflected in your Nexford Wallet.",
};

const PaymentPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();
  const stripe = useStripe();

  const [providersList, setProvidersList] = useState<Array<CurrencyProvider>>();

  const [presetAmount, setPresetAmount] = useState<number>();
  const [totalCost, setTotalCost] = useState<number>();

  const [paymentSubmitting, setPaymentSubmitting] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState<string>();
  const [paymentError, setPaymentError] = useState<string>();

  const [flutterwaveConfig, setFlutterwaveConfig] = useState<FlutterwaveConfig>();
  const [edubancConfig, setEdubancConfig] = useState<EdubancConfig>();
  const [flywireConfig, setFlywireConfig] = useState<FlywireConfig>();

  const { data: personalInfoData } = useLearner(!!userAccount, instance);
  const {
    data: tuitionData,
    isLoading: tuitionDataLoading,
    error: tuitionDataError,
    refetch: tuitionDataRefetch,
  } = useTuitionInfo(instance);

  const {
    data: providersRequest,
    isFetching: providersRequestFetching,
    error: providersRequestError,
    refetch: providersRequestFetch,
  } = useProvidersOptions(instance, totalCost || 0);

  const payApplicationFeeFlutterwave = usePayApplicationFeeFlutterwave();
  const applicationFeeStripe = useApplicationFeeStripe();

  // Response handler for the currencies and payment providers
  const handleProvidersResponse = useCallback(() => {
    if (providersRequest && !providersRequestFetching && !providersRequestError) {
      if (providersRequest.Currencies) setProvidersList(providersRequest.Currencies);
      if (providersRequest.Currencies) setProvidersList(providersRequest.Currencies);
      if (providersRequest.FlywireConfig) setFlywireConfig(providersRequest.FlywireConfig);
      if (providersRequest.FlutterWaveConfig) setFlutterwaveConfig(providersRequest.FlutterWaveConfig);
      if (providersRequest.EdubancConfig) setEdubancConfig(providersRequest.EdubancConfig);
    }
  }, [providersRequest, providersRequestError, providersRequestFetching]);

  // Call update events when a new amount is returneds in the form
  const updateTotalCost = (newAmount?: number) => {
    if (newAmount !== totalCost) {
      setTotalCost(newAmount);
      setPresetAmount(0);
    } else {
      setPresetAmount(0);
      handleProvidersResponse();
    }
  };

  // Call fetch when totalCost has been changed
  useEffect(() => {
    if (totalCost) providersRequestFetch();
  }, [providersRequestFetch, totalCost]);

  // When providerRequest returns a new response, call the response handler
  useEffect(() => {
    handleProvidersResponse();
  }, [providersRequest, providersRequestFetching, providersRequestError, handleProvidersResponse]);

  // Exit the payment screen, and go back to the invoices list
  const returnToInvoiceList = () => {
    payApplicationFeeFlutterwave.reset();
    applicationFeeStripe.reset();
    tuitionDataRefetch();
    setPaymentError(undefined);
    setPaymentSuccess(undefined);
    setProvidersList([]);
    setTotalCost(undefined);
    navigate(LocalRoutes.WALLET);
  };

  const clearProvidersList = () => {
    setProvidersList([]);
  };

  const resetOfPaymentEvents = () => {
    payApplicationFeeFlutterwave.reset();
    applicationFeeStripe.reset();
    setPaymentError(undefined);
    setPaymentSuccess(undefined);
  };

  useEffect(() => {
    if (tuitionData) {
      if (searchParams.get("amount") === "full") {
        setPresetAmount(tuitionData.totalAmountDue);
      } else if (Number(searchParams.get("amount"))) {
        setPresetAmount(Number(searchParams.get("amount")));
      }
    }
  }, [searchParams, tuitionData]);

  const confirmStripePayment = useCallback(
    async (paymentIntent: string, saveCard?: boolean) => {
      const intentActionResponse = await stripe?.handleCardAction(paymentIntent);

      const paymentIntentId = intentActionResponse?.paymentIntent ? intentActionResponse.paymentIntent.id : null;

      if (!paymentIntentId) {
        setPaymentError(Copy.paymentAuthenticationErrorMessage);
        setPaymentSubmitting(false);
        return;
      }

      const stripeConfirmationPayload: StripeApplicationFeePayload = {
        PaymentIntent: {
          Action: "Confirm",
          PaymentIntentId: paymentIntentId,
          SaveCard: saveCard || false,
        },
        Amount: Number(totalCost?.toFixed(2)),
      };

      applicationFeeStripe.mutate({ payload: stripeConfirmationPayload, msalInstance: instance });
    },
    [applicationFeeStripe, instance, stripe, totalCost],
  );

  const handleStripePaymentCreate = (inProgress: boolean) => {
    // Before stripe progresses to the payment stage, it has a separate payment creation event that needs time to complete
    setPaymentSubmitting(inProgress);
  };

  const handleStripePayment = async (paymentMethodId: string, saveCard?: boolean) => {
    const stripeCreationPayload: StripeApplicationFeePayload = {
      PaymentIntent: {
        Action: "Create",
        PaymentMethodId: paymentMethodId,
        SaveCard: saveCard || false,
      },
      Amount: Number(totalCost?.toFixed(2)),
    };
    applicationFeeStripe.mutate({ payload: stripeCreationPayload, msalInstance: instance });
  };

  useEffect(() => {
    if (applicationFeeStripe.isSuccess) {
      if (applicationFeeStripe.data.Result === CHARGED) {
        setPaymentSuccess(Copy.paymentDefaultSuccessMessage);
        setPaymentSubmitting(false);
      } else if (applicationFeeStripe.data.Result === REQUIRES_AUTHENTICATION) {
        confirmStripePayment(
          applicationFeeStripe.data.PaymentIntentClientSecret,
          applicationFeeStripe.variables?.payload.PaymentIntent.SaveCard,
        );
      } else {
        const mappedError = mapStripeErrorCode(applicationFeeStripe.data.Result);
        setPaymentError(mappedError.error);
        setPaymentSubmitting(false);
      }
    }
    if (applicationFeeStripe.isError) {
      const error = applicationFeeStripe.error as HttpError;
      setPaymentError(error.message);
      setPaymentSubmitting(false);
    }
  }, [applicationFeeStripe, confirmStripePayment]);

  const handlePaypalSuccess = async () => {
    setPaymentSuccess(Copy.paymentDefaultSuccessMessage);
    setPaymentSubmitting(false);
  };

  const handleFlywire = async (data: FlywireCallback) => {
    if (data.Status === "success") {
      setPaymentSuccess(Copy.paymentDefaultSuccessMessage);
      setPaymentSubmitting(false);
    } else if (data.Status === "error") {
      setPaymentError(Copy.flywireErrMsg);
      setPaymentSubmitting(false);
    }
  };

  const handleFlutterwave = async (data: FlutterwaveCallback) => {
    if (data.status === "successful") {
      const transactionId = data.transaction_id;
      const flutterwaveReference = data.tx_ref;
      const { status } = data;
      const { amount } = data;
      const { currency } = data;

      const flutterwavePayload: FlutterwaveApplicationFeePayload = {
        TransactionId: transactionId,
        FlutterwaveReference: flutterwaveReference,
        Status: status,
        Amount: amount,
        Currency: currency,
      };

      payApplicationFeeFlutterwave.mutate({ payload: flutterwavePayload, msalInstance: instance });
    }
  };

  useEffect(() => {
    if (payApplicationFeeFlutterwave.isSuccess) {
      if (payApplicationFeeFlutterwave.data.Result === CHARGED) {
        setPaymentSuccess(Copy.paymentDefaultSuccessMessage);
      }
      setPaymentSubmitting(false);
    }
    if (payApplicationFeeFlutterwave.isError) {
      const error = payApplicationFeeFlutterwave.error as HttpError;
      setPaymentError(error.message);
      setPaymentSubmitting(false);
    }
  }, [payApplicationFeeFlutterwave]);

  if (!stripe) {
    return (
      <div className="wallet__wrapper">
        <NxuContentLoading />
      </div>
    );
  }

  return (
    <PageContent className="wallet__wrapper">
      <div className={cx("wallet__title wallet__title--action")}>
        <IonFabButton size="small" color="light" onClick={() => returnToInvoiceList()}>
          <IonIcon icon={chevronBack} />
        </IonFabButton>
        <h2>Make a payment</h2>
      </div>
      {tuitionDataLoading && <NxuContentLoading />}

      {!tuitionDataLoading && (
        <div className="wallet__inner" data-testid="inner-page">
          {!!tuitionDataError && (
            <NxuAlert message={tuitionData ? `${walletErrMsg.refetchTuitionInfo}` : tuitionDataError.message} />
          )}

          {!!tuitionData && (
            <div className="wallet__inner">
              {!paymentSuccess && (
                <PaymentInfo
                  tuitionData={tuitionData}
                  updateTotalCost={updateTotalCost}
                  presetAmount={presetAmount}
                  providersList={providersList}
                  clearProvidersList={clearProvidersList}
                  providersRequestLoading={providersRequestFetching}
                  providersRequestError={providersRequestError?.message}
                  paymentSubmitting={paymentSubmitting || payApplicationFeeFlutterwave.isPending}
                />
              )}

              {!!providersRequestError && <NxuAlert message={providersRequestError.message} />}

              {!!personalInfoData && !providersRequestError && !!providersList?.length && (
                <PaymentProvider
                  callbackId={providersRequest?.CallbackId}
                  currencies={providersList}
                  learnerId={tuitionData.learnerId}
                  applicantPersonalData={personalInfoData}
                  paymentSubmitting={paymentSubmitting}
                  paymentError={paymentError}
                  fullReset={resetOfPaymentEvents}
                  paymentSuccess={paymentSuccess}
                  flywireConfig={flywireConfig}
                  flutterwaveConfig={flutterwaveConfig}
                  edubancConfig={edubancConfig}
                  onSubmitPaypal={handlePaypalSuccess}
                  onSubmitFlywire={handleFlywire}
                  onSubmitFlutterwave={handleFlutterwave}
                  handleStripePayment={handleStripePayment}
                  handleStripePaymentCreate={handleStripePaymentCreate}
                />
              )}
            </div>
          )}

          {(!!tuitionDataError || !!providersRequestError) && <GetInTouch />}
        </div>
      )}
    </PageContent>
  );
};

const PaymentPageWithStripe = () => {
  const { stripeApiKey, loading, stripeLoadingError } = useStripeContext();

  if (loading) {
    return (
      <PageContent className="wallet__wrapper">
        <NxuContentLoading />
      </PageContent>
    );
  }

  if (stripeLoadingError || !stripeApiKey) {
    return (
      <PageContent className="wallet__wrapper">
        <NxuAlert
          type="error"
          message={
            stripeLoadingError ||
            "There was an error in loading our payment processor, please refresh the page to try again"
          }
        />
      </PageContent>
    );
  }

  const stripePromise = loadStripe(stripeApiKey);

  return (
    <Elements stripe={stripePromise}>
      <PaymentPage />
    </Elements>
  );
};

export default PaymentPageWithStripe;
