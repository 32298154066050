import React, { useEffect, useState } from "react";
import { schoolOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

import { useMsal } from "@azure/msal-react";

import { useAuthContext } from "utils/context/Auth";
import { useLearnerPath } from "utils/hooks/program";
import { useLearner } from "utils/hooks/learners";
import { ProgramProviderOptions } from "types/learner";

import { NxuContentLoading } from "@nexford/nexford-ui-component-library";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import LearnerPathList from "components/molecule/learner-path-list";
import LearnerExternalCard from "components/molecule/learner-external-card/learner-external-card";

import "./academics.scss";

/**
 * Academics module rendering current enrollments and program details
 */
const Academics = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();

  const [readableProgramName, setReadableProgramName] = useState<string>("");

  const {
    data: learnerProfile,
    isLoading: learnerProfileLoading,
    error: learnerProfileError,
  } = useLearner(!!userAccount, instance);

  const {
    data: learnerPathData,
    isLoading: learnerPathLoading,
    error: learnerPathError,
  } = useLearnerPath(
    !!(
      !!userAccount &&
      learnerProfile?.programEnrollmentId &&
      (learnerProfile?.provider === ProgramProviderOptions.NEXFORD || !learnerProfile?.provider)
    ),
    instance,
    learnerProfile?.programEnrollmentId,
  );

  const showExternalCard = !!(learnerProfile?.provider && learnerProfile.provider !== ProgramProviderOptions.NEXFORD);
  const showLearnerPath = learnerProfile?.provider === ProgramProviderOptions.NEXFORD || !learnerProfile?.provider;

  useEffect(() => {
    if (learnerProfile?.productCode) {
      setReadableProgramName(`${learnerProfile.programFriendlyName} ${learnerProfile.productType}`);
    }
  }, [learnerProfile]);

  return (
    <PageContent className="academics-page">
      <h1>
        <IonIcon icon={schoolOutline} />
        My Program
      </h1>

      {learnerProfileLoading && <NxuContentLoading />}

      {showExternalCard && (
        <LearnerExternalCard
          learnerProfile={learnerProfile}
          isLoading={learnerProfileLoading}
          error={learnerProfileError}
        />
      )}

      {showLearnerPath && (
        <LearnerPathList
          list={learnerPathData}
          programTitle={readableProgramName}
          specializations={learnerProfile?.specializations}
          isLoading={learnerProfileLoading || learnerPathLoading}
          error={learnerProfileError || learnerPathError}
        />
      )}
    </PageContent>
  );
};

export default Academics;
