import React, { useEffect, useState } from "react";
import { IonButton, IonIcon, IonPopover, isPlatform } from "@ionic/react";
import cx from "classnames";

import config from "config";
import { ANDROID_APP_URL, IOS_APP_URL, NEXFORD_SEMINARS_FAQ } from "constants/external-routes";
import { CourseEnrollmentStatus, CourseEnrollmentSubStatus, LearningPathCourse } from "types/learning-path";
import { isMobileOS } from "utils/detect-device";
import { getEnrollmentStatusLabel } from "utils/program-utils";
import { formatShortDate } from "utils/format-date.utils";

import { NxuAlert, NxuContentLoading } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import SecondaryButton from "components/atom/button-secondary";
import LearnerPathModal from "components/molecule/learner-path-modal";

import "./learner-path-list.scss";
import { informationCircleOutline, swapHorizontalOutline } from "ionicons/icons";

export interface LearnerPathListProps {
  programTitle?: string;
  specializations?: string[];
  list?: Array<LearningPathCourse>;
  isLoading?: boolean;
  error?: Error | null;
}

/**
 * Display a list of the user's course enrollments
 */
const LearnerPathList = ({ programTitle, list, isLoading, error, specializations }: LearnerPathListProps) => {
  const [seminarsList, setSeminarsList] = useState<Array<LearningPathCourse>>();

  const [currentCourse, setCurrentCourse] = useState<LearningPathCourse | null>(null);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);

  useEffect(() => {
    if (list?.length) {
      const seminarItems: Array<LearningPathCourse> = [];
      list.forEach((item) => {
        if (item.isSeminar) {
          seminarItems.push(item);
        }
      });
      setSeminarsList(seminarItems);
    }
  }, [list]);

  const closeCourseModal = () => {
    setIsCourseModalOpen(false);
    setCurrentCourse(null);
  };

  const openCourseModal = (course: LearningPathCourse) => {
    setCurrentCourse(course);
    setIsCourseModalOpen(true);
  };

  const GradeChip: React.FunctionComponent<{ grade: string; status: "passed" | "failed" }> = ({ grade, status }) => (
    <span
      className={cx(
        "learner-path__item-chip learner-path__item-chip--grade",
        status === "failed" && "learner-path__item-chip--grade-failed",
      )}
    >
      <>
        {grade}
        <span>{status === "failed" ? "Failed" : "Passed"}</span>
      </>
    </span>
  );

  const LearnerPathStatus: React.FunctionComponent<{ item: LearningPathCourse }> = ({ item }) => {
    if (item.isExternalTransfer) {
      return (
        <div className="learner-path__item-transfer">
          <IonIcon
            className="learner-path__item-transfer__icon"
            icon={swapHorizontalOutline}
            aria-label="Course is an External Transfer"
            title="External transfer"
          />
        </div>
      );
    }

    if (item.status === "completed" && item.substatus === "passed") {
      return <GradeChip grade={item.grade || ""} status="passed" />;
    }

    if (item.status === CourseEnrollmentStatus.Enrolled && item.substatus) {
      return (
        <span className="learner-path__item-chip learner-path__item-chip--status">
          {getEnrollmentStatusLabel(item.substatus)}
        </span>
      );
    }

    if (item.isSeminar) {
      return (
        <div className="learner-path__item-chips">
          {item.substatus === CourseEnrollmentSubStatus.Failed && (
            <GradeChip grade={item.grade || ""} status="failed" />
          )}
          <span className="learner-path__item-chip learner-path__item-chip--availability">
            {item.nextAvailableAt ? (
              <>
                Available:
                <span>{formatShortDate(item.nextAvailableAt)}</span>
              </>
            ) : (
              <>Coming Soon</>
            )}
          </span>
        </div>
      );
    }

    if (item.substatus === CourseEnrollmentSubStatus.Failed) {
      return <GradeChip grade={item.grade || ""} status="failed" />;
    }

    return <></>;
  };

  const LearnerPathItem: React.FunctionComponent<{ item: LearningPathCourse }> = ({ item }) => (
    <li className="learner-path__item">
      <button aria-label={`Open course description for ${item.courseName}`} onClick={() => openCourseModal(item)}>
        <div>
          <span className="learner-path__item-chip learner-path__item-chip--id">{item.courseCode}</span>
          <span className="learner-path__item-name">{item.courseName}</span>
        </div>
        <LearnerPathStatus item={item} />
      </button>
    </li>
  );

  const SeminarItem: React.FunctionComponent<{ item: LearningPathCourse }> = ({ item }) => (
    <li className="learner-path__item">
      <div>
        <span className="learner-path__item-chip learner-path__item-chip--id">{item.courseCode}</span>
        <span className="learner-path__item-name">{item.courseName}</span>
      </div>
      <LearnerPathStatus item={item} />
    </li>
  );

  if (isLoading) {
    return <NxuContentLoading />;
  }

  return (
    <section className="learner-path">
      <div className="learner-path__title">
        <h2>{programTitle}</h2>
        {!!specializations?.length && (
          <p>
            {specializations.length > 1 ? "Specializations:" : "Specialization:"}
            {specializations.map((item) => (
              <span>{item}</span>
            ))}
          </p>
        )}
      </div>

      {error && (
        <NxuAlert
          message={`We're having difficulty getting your program summary: ${error.message}. Please refresh the page and if the problem persists,
          contact support for help.`}
        />
      )}

      {!!list && (
        <div className="learner-path__list-wrapper">
          <div className="learner-path__list-title">
            <h2>Your Learning Path</h2>
          </div>
          <ul data-testid="learner-path-list">
            {list.map((item) => !item.isSeminar && <LearnerPathItem key={item.courseCode} item={item} />)}
          </ul>
        </div>
      )}

      {!!seminarsList?.length && (
        <div className="learner-path__list-wrapper">
          <div className="learner-path__list-title">
            <h2>Your Seminars</h2>
            <IonButton id="seminars-tooltip-trigger" aria-label="Open the seminars tooltip" shape="round" size="small">
              <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
            </IonButton>
            <IonPopover
              className="seminars-tooltip"
              trigger="seminars-tooltip-trigger"
              triggerAction="click"
              side="bottom"
              alignment="start"
            >
              <p>
                You are required to take seminars when they are available.{" "}
                <a href={NEXFORD_SEMINARS_FAQ} target="_blank">
                  More information
                </a>
              </p>
            </IonPopover>
          </div>
          <ul data-testid="learner-path-seminars">
            {seminarsList.map((item) => (
              <SeminarItem key={item.courseCode} item={item} />
            ))}
          </ul>
        </div>
      )}

      {!!list && (
        <CardPanel className="learner-path__canvas-block">
          <p>Want to see all of your course materials?</p>
          <SecondaryButton href={config.url.canvas}>Go to Canvas</SecondaryButton>
          {isMobileOS() && (
            <>
              <p>Or take Canvas with you on your phone</p>
              <SecondaryButton href={isPlatform("android") ? ANDROID_APP_URL : IOS_APP_URL}>
                Install Canvas
              </SecondaryButton>
            </>
          )}
        </CardPanel>
      )}
      <LearnerPathModal course={currentCourse} isOpen={isCourseModalOpen} closeModal={closeCourseModal} />
    </section>
  );
};
export default LearnerPathList;
