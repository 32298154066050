import React from "react";
import cx from "classnames";

import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

import CardPanel from "../card-panel";

import "./success-panel.scss";

export interface SuccessPanelProps {
  children: React.ReactNode;
  className?: string;
  hideSuccessIcon?: boolean;
  testId?: string;
}

/**
 * Standardised layout panel
 */
const SuccessPanel = ({ children, hideSuccessIcon, className, testId }: SuccessPanelProps) => (
  <CardPanel className={cx("success-card-panel", className)} testId={testId}>
    {!hideSuccessIcon && <IonIcon color="primary" className="success-icon" icon={checkmarkCircleOutline} />}
    {children}
  </CardPanel>
);

export default SuccessPanel;
