import { IDeclineAgreementRequest, IOfficialNameRequest } from "types/agreement";

export default {
  readmission: {
    getActiveReadmissionRequest: async (getBearerToken: () => Promise<string>) => {
      const token = await getBearerToken();
      const response = await fetch("/api/readmission/learners/self/readmission-request/approved", {
        method: "GET",
        headers: [["Authorization", `Bearer ${token}`]],
      });
      return response;
    },
    getOfficialName: async (getBearerToken: () => Promise<string>) => {
      const token = await getBearerToken();
      const response = await fetch("/api/readmission/learners/self/officialName", {
        method: "GET",
        headers: [["Authorization", `Bearer ${token}`]],
      });
      return response;
    },
    getEnrollmentAgreement: async (getBearerToken: () => Promise<string>) => {
      const token = await getBearerToken();
      const response = await fetch("/api/readmission/learners/self/readmission-request/active/agreement", {
        method: "get",
        headers: [["Authorization", `Bearer ${token}`]],
      });
      return response;
    },
    getSapPlan: async (getBearerToken: () => Promise<string>) => {
      const token = await getBearerToken();
      const response = await fetch("/api/readmission/learners/self/readmission-request/active/sap-plan", {
        method: "get",
        headers: [["Authorization", `Bearer ${token}`]],
      });
      return response;
    },
    signEnrollmentAgreement: async (getBearerToken: () => Promise<string>, officialName: IOfficialNameRequest) => {
      const token = await getBearerToken();
      const response = await fetch("/api/readmission/learners/self/readmission-request/active/agreement/signed", {
        method: "PUT",
        headers: [
          ["Authorization", `Bearer ${token}`],
          ["content-type", "application/json"],
        ],
        body: JSON.stringify(officialName),
      });
      return response;
    },
    declineEnrollmentAgreement: async (
      getBearerToken: () => Promise<string>,
      declineRequest: IDeclineAgreementRequest,
    ) => {
      const token = await getBearerToken();
      const response = await fetch("/api/readmission/learners/self/readmission-request/active/agreement/declined", {
        method: "PUT",
        headers: [
          ["Authorization", `Bearer ${token}`],
          ["content-type", "application/json"],
        ],
        body: JSON.stringify(declineRequest),
      });
      return response;
    },
  },
};
