import { EnvironmentPrefix } from "./feature-flags";

export const ANDROID_APP_URL = "https://play.google.com/store/apps/details?id=com.instructure.candroid&hl=en_US";
export const IOS_APP_URL = "https://apps.apple.com/us/app/canvas-student/id480883488";

export const PAYPAL_URL = "http://paypal.me/nexford";

export const NEXFORD_PAGE_PAYMENT = "https://www.nexford.edu/payment";
export const NEXFORD_PRIVACY_POLICY = "https://www.nexford.edu/privacy-policy";

export const NEXFORD_MAIL_HELP = "help@nexford.org";
export const NEXFORD_MAIL_BILLINGS = "billings@nexford.org";
export const NEXFORD_MAIL_SUCCESS = "success@nexford.org";
export const NEXFORD_MAIL_ADMISSION = "admissions@nexford.org";
export const NEXFORD_MAIL_TRANSCRIPTS = "transcripts@nexford.org";
export const NEXFORD_MAIL_REGISTRAR = "registrar@nexford.org";

export const NEXFORD_CALENDAR_SUCCESS =
  "https://outlook.office365.com/owa/calendar/LearnerSuccess@nexford.org/bookings/";

export const NEXFORD_MESSENGER = "http://m.me/nexford";

export const NEXFORD_WHATSAPP = "https://api.whatsapp.com/send/?phone=12022802049";
export const NEXFORD_WHATSAPP_SUCCESS = "https://api.whatsapp.com/send/?phone=12026601426";
export const NEXFORD_WHATSAPP_BILLINGS = "https://api.whatsapp.com/send/?phone=12063178160";

export const COURSERA_DISCORD = "https://discord.gg/YmRTbgWZ6v";

export const NEXFORD_TRANSCRIPT_FAQ = "https://nexford.freshdesk.com/a/solutions/articles/64000254585";
export const NEXFORD_AFFIDAVIT_FAQ =
  "https://nexford.freshdesk.com/support/solutions/articles/64000297052-what-is-an-affidavit-and-how-can-i-provide-one-";
export const NEXFORD_ENGLISH_CERTIFICATE_FAQ =
  "https://nexford.freshdesk.com/support/solutions/articles/64000297059-how-can-i-provide-proof-of-english-proficiency-";
export const NEXFORD_COPY_OF_DEGREE_CERTIFICATE_FAQ =
  "https://nexford.freshdesk.com/support/solutions/articles/64000297361-what-is-a-copy-of-my-degree-certificate-and-why-do-you-need-it-";

export const NEXFORD_SEMINARS_FAQ = "https://nexford.freshdesk.com/a/solutions/articles/64000299320";

export const NEXFORD_CANVAS_COURSE = `${process.env.REACT_APP_NEXFORD_BASE_URL}/api/canvas/deeplink/courses`;
