import { IPublicClientApplication } from "@azure/msal-browser";
import { useQuery } from "@tanstack/react-query";
import { DeadlineItem } from "types/learner";

import { apiGet } from "./api-client";

export const deadlinesErrMsg = {
  fetchDeadlines:
    "Uh-oh! There was an error on getting your upcoming deadlines.  Please refresh and try again or contact support.",
};

const fetchDeadlines = async (msalInstance: IPublicClientApplication): Promise<Array<DeadlineItem>> =>
  apiGet(`/api/learnerdeadline/self/deadlines`, deadlinesErrMsg.fetchDeadlines, msalInstance);

const useDeadlines = (hasAccount: boolean, msalInstance: IPublicClientApplication) =>
  useQuery({
    queryKey: ["deadlines-list"],
    queryFn: () => fetchDeadlines(msalInstance),
    throwOnError: false,
    enabled: hasAccount,
    retry: 3,
    staleTime: 600000,
  });

export { useDeadlines };
