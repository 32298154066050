import {
  NEXFORD_AFFIDAVIT_FAQ,
  NEXFORD_COPY_OF_DEGREE_CERTIFICATE_FAQ,
  NEXFORD_ENGLISH_CERTIFICATE_FAQ,
  NEXFORD_MAIL_ADMISSION,
  NEXFORD_MAIL_REGISTRAR,
  NEXFORD_MAIL_TRANSCRIPTS,
  NEXFORD_TRANSCRIPT_FAQ,
} from "constants/external-routes";
import { checkmarkCircleOutline, closeCircleOutline, alertCircleOutline, timeOutline } from "ionicons/icons";

import { CommonAepDocument, DocumentStatus, DocumentStatusOptions, DocumentStatusTypeOptions } from "types/learner";

export function getDocumentTypeLabel(type: string): string {
  switch (type) {
    case DocumentStatusTypeOptions.id:
      return "ID";
    case DocumentStatusTypeOptions.photo:
      return "Photo of yourself";
    default:
      return type;
  }
}

export function getDocumentTypeHelp(type: string): string {
  switch (type) {
    case DocumentStatusTypeOptions.id:
      return "Upload your ID";
    case DocumentStatusTypeOptions.photo:
      return "Upload a photo";
    default:
      return `Upload a ${type}`;
  }
}

export function getDocumentStatusIcon(status: DocumentStatus): string {
  switch (status) {
    case DocumentStatusOptions.failed:
      return closeCircleOutline;
    case DocumentStatusOptions.passed:
      return checkmarkCircleOutline;
    case DocumentStatusOptions.notReviewed:
      return timeOutline;
    default:
      return alertCircleOutline;
  }
}

export function getDocumentStatusLabel(type: string, status: DocumentStatus, comment?: string): string {
  switch (status) {
    case DocumentStatusOptions.failed:
      return `Your document was rejected. Here's why: "${comment}"`;
    case DocumentStatusOptions.passed:
      return "Verified";
    case DocumentStatusOptions.notReviewed:
      return "Pending Verification";
    default:
      return getDocumentTypeHelp(type);
  }
}

export function getAepDocumentLabel(aepDocumentName: string): string {
  switch (aepDocumentName) {
    case CommonAepDocument.GradesTranscript:
      return "Grades Transcript";
    case CommonAepDocument.EnglishCertificate:
      return "English Certificate";
    case CommonAepDocument.AdditionalId:
      return "Additional ID";
    default:
      return aepDocumentName;
  }
}
export function getAepDocumentHelpText(aepDocumentName: string): string {
  switch (aepDocumentName) {
    case CommonAepDocument.GradesTranscript:
      return "Connect with your previous institution and request for it to be sent to";
    case CommonAepDocument.EnglishCertificate:
      return "Submit proof of English proficiency to";
    case CommonAepDocument.Affidavit:
      return "Please provide an affidavit stating the reasons for your information mismatch to";
    case CommonAepDocument.AdditionalId:
      return "Send a copy of your government-ID to";
    case CommonAepDocument.CopyOfDegreeCertificate:
      return "Submit a copy of your degree certificate to";
    default:
      return "Send to";
  }
}

export function getAepDocumentDestinationMail(aepDocumentName: string): string {
  switch (aepDocumentName) {
    case CommonAepDocument.GradesTranscript:
      return NEXFORD_MAIL_TRANSCRIPTS;
    default:
      return NEXFORD_MAIL_ADMISSION;
  }
}

export function getAepUrlFAQ(aepDocumentName: string): string | null {
  switch (aepDocumentName) {
    case CommonAepDocument.GradesTranscript:
      return NEXFORD_TRANSCRIPT_FAQ;
    case CommonAepDocument.EnglishCertificate:
      return NEXFORD_ENGLISH_CERTIFICATE_FAQ;
    case CommonAepDocument.Affidavit:
      return NEXFORD_AFFIDAVIT_FAQ;
    case CommonAepDocument.CopyOfDegreeCertificate:
      return NEXFORD_COPY_OF_DEGREE_CERTIFICATE_FAQ;
    default:
      return null;
  }
}
