import { CourseEnrollmentSubStatus } from "types/learning-path";

export function getEnrollmentStatusLabel(status: string): string {
  switch (status) {
    case CourseEnrollmentSubStatus.InProgress:
      return "In Progress";
    case CourseEnrollmentSubStatus.Preview:
      return "Preview";
    case CourseEnrollmentSubStatus.Previewing:
      return "Preview";
    default:
      console.warn(`${status} is unsupported`);
      return "";
  }
}
