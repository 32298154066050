import React from "react";
import { IonIcon } from "@ionic/react";
import { giftOutline, logOutOutline } from "ionicons/icons";
import { useMsal } from "@azure/msal-react";

import { useAuthContext } from "utils/context/Auth";
import { useLearner } from "utils/hooks/learners";
import { signOutClickHandler } from "utils/profile-utils";

import { NxuAlert, NxuContentLoading, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import GetInTouch from "components/atom/get-in-touch";

import "./profile-content.scss";
import { ProgramProviderOptions } from "types/learner";

/**
 * Profile content section with learner details
 */
const ProfileContent = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();

  const {
    data: learnerProfile,
    isLoading: learnerProfileLoading,
    error: learnerProfileError,
  } = useLearner(!!userAccount, instance);

  const readableProductType =
    learnerProfile?.provider === ProgramProviderOptions.COURSERA
      ? "Professional Certificate"
      : learnerProfile?.productType;

  return (
    <div className="profile-page__content" data-testid="profile-page__content">
      {learnerProfileLoading && <NxuContentLoading />}
      {learnerProfileError && (
        <NxuAlert
          message={`We're having difficulty getting your program summary: ${learnerProfileError.message}. Please refresh the page and if the problem persists,
          contact support for help.`}
        />
      )}

      {learnerProfile && (
        <CardPanel className="profile-page__details">
          <ul>
            <li>
              <span>Full Name</span>
              <span data-hj-suppress>
                {learnerProfile.firstName} {learnerProfile?.lastName}
              </span>
            </li>
            <li>
              <span>Learner ID</span>
              <span data-hj-suppress>{learnerProfile.learnerId}</span>
            </li>
            <li>
              <span>Location</span>
              <span data-hj-suppress>{learnerProfile.countryOfResidence}</span>
            </li>
            <li>
              <span>Email Address</span>
              <span data-hj-suppress>{learnerProfile.email}</span>
            </li>
            {!!learnerProfile.phoneNumber && (
              <li>
                <span>Contact Number</span>
                <span data-hj-suppress>{learnerProfile.phoneNumber}</span>
              </li>
            )}
            <li>
              <span>Program</span>
              <span>
                {learnerProfile.programAcademicName} {readableProductType}
              </span>
            </li>
            <li className="profile-page__details--capitalise">
              <span>Status</span>
              <span>{learnerProfile.programStatus}</span>
            </li>
          </ul>
        </CardPanel>
      )}

      <NxuPrimaryButton
        className="profile-page__referral"
        size="default"
        expand="full"
        href="https://hub.nexford.edu/refer-program"
        target="_blank"
      >
        REFER A FRIEND TO NEXFORD
        <IonIcon slot="end" icon={giftOutline} />
      </NxuPrimaryButton>

      <GetInTouch>
        <p>Need to change your account information? Contact Us</p>
      </GetInTouch>

      <NxuPrimaryButton
        className="profile-page__logout"
        color="danger"
        expand="block"
        size="large"
        onClick={() => signOutClickHandler(instance)}
      >
        Sign out
        <IonIcon slot="end" icon={logOutOutline}></IonIcon>
      </NxuPrimaryButton>
    </div>
  );
};

export default ProfileContent;
