import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useMsal } from "@azure/msal-react";
import { IonButton, IonCard, IonCardContent, IonIcon } from "@ionic/react";

import { useAuthContext } from "utils/context/Auth";
import { useDocumentStatus } from "utils/hooks/learners";
import { getDocumentStatusIcon, getDocumentStatusLabel, getDocumentTypeLabel } from "utils/learner-utils";

import UploadModal, { UploadModalTypes } from "components/molecule/upload-modal/upload-modal";

import "./id-documents-content.scss";
import { DocumentStatus, DocumentStatusOptions } from "types/learner";
import { cloudUploadOutline } from "ionicons/icons";
import DocumentsPanel from "components/molecule/documents-panel";

/**
 * Documents content section
 */
const IdDocumentsContent = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();

  const {
    data: documentStatus,
    isLoading: documentStatusLoading,
    error: documentStatusError,
    refetch: documentStatusRefetch,
  } = useDocumentStatus(!!userAccount, instance);

  const [modalType, setModalType] = useState<UploadModalTypes>();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isUploadSuccessful, setIsUploadSuccessful] = useState<boolean>(false);

  useEffect(() => {
    if (isUploadSuccessful) {
      documentStatusRefetch();
    }
  }, [isUploadSuccessful, documentStatusRefetch]);

  const canUpload = (status: DocumentStatus) =>
    status === DocumentStatusOptions.failed || status === DocumentStatusOptions.notUploaded;

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
    setModalType(undefined);
  };

  const openUploadModal = (type: UploadModalTypes) => {
    setIsUploadSuccessful(false);
    setModalType(type);
    setIsUploadModalOpen(true);
  };

  return (
    <DocumentsPanel
      title="Identity Documents"
      documentStatusError={documentStatusError}
      documentStatusLoading={documentStatusLoading}
    >
      {isUploadSuccessful && (
        <IonCard color={"primary"} className="profile-page__documents-success" data-testid="document-upload-success">
          <IonCardContent>
            You've successfully uploaded a new document. It is now with the team at Nexford for review
          </IonCardContent>
        </IonCard>
      )}

      {documentStatus?.documents && (
        <div className="documents-list">
          {documentStatus.documents.map((docItem) => (
            <div key={`id-document-item-${docItem.documentId}`} className="id-document-item">
              <div className="id-document-item__column">
                <IonIcon
                  className={cx(`id-document-item__status-icon id-document-item__status-icon--${docItem.status}`)}
                  icon={getDocumentStatusIcon(docItem.status)}
                />
                <div className="id-document-item__details">
                  <span className="id-document-item__details-title">{getDocumentTypeLabel(docItem.documentId)}</span>
                  <span
                    className={cx(
                      `id-document-item__details-status id-document-item__details-status--${docItem.status}`,
                    )}
                  >
                    {getDocumentStatusLabel(docItem.documentId, docItem.status, docItem.comment)}
                  </span>
                </div>
              </div>
              <div>
                {canUpload(docItem.status) && (
                  <IonButton
                    aria-label={`Open the ${docItem.documentId} upload form`}
                    fill="clear"
                    color="secondary"
                    onClick={() => openUploadModal(docItem.documentId)}
                  >
                    <IonIcon slot="icon-only" icon={cloudUploadOutline}></IonIcon>
                  </IonButton>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <UploadModal
        uploadType={modalType}
        isOpen={isUploadModalOpen}
        closeModal={closeUploadModal}
        setUploadSuccessful={setIsUploadSuccessful}
      />
    </DocumentsPanel>
  );
};

export default IdDocumentsContent;
