import React from "react";

// Components
import IframeGoogleDoc from "./google-pdf-viewer";
// Utils
import { getMobileOperatingSystem } from "../../../utils/detect-device";

import "./document-viewer.scss";

export interface DocumentViewerProps {
  frameTitle: string;
  file: string;
  fileIsPdf?: boolean;
  url?: string;
}

/**
 * Component for rendering html or PDF docs within the app
 */
const DocumentViewer = ({ file, url, frameTitle, fileIsPdf }: DocumentViewerProps) => {
  const operatingSystem = getMobileOperatingSystem();
  const encodedUrl = url && encodeURIComponent(url);

  if (fileIsPdf) {
    if (encodedUrl && (operatingSystem === "Android" || operatingSystem === "iOS")) {
      return <IframeGoogleDoc url={encodedUrl} frameTitle={frameTitle} />;
    }

    return (
      <iframe
        className="document-viewer__pdf"
        title={frameTitle}
        style={{ border: "0" }}
        src={`data:application/pdf;base64,${file}`}
      />
    );
  }

  return <iframe className="document-viewer__html" title={frameTitle} style={{ border: "0" }} srcDoc={file} />;
};

export default DocumentViewer;
