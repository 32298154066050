import { useLinkedInSocialShareLink } from "utils/hooks/social-share";
import "./linkedin-social-share-button.scss";
import { ReactComponent as LinkedInLogo } from "assets/img/linkedin-white-logo.svg";

interface LinkedInSocialShareButtonProps {
  productCode: string;
}

const LinkedInSocialShareButton = (props: LinkedInSocialShareButtonProps) => {
  const { productCode } = props;
  const { data: shareLink } = useLinkedInSocialShareLink(productCode);

  const openWindow = () => {
    // Desired width and height of the window
    const width = 800;
    const height = 600;

    // Calculate the position for the window to be centered
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    // window.open takes the URL, window name, and window features
    window.open(shareLink?.shareUrl, "newWindow", `width=${width},height=${height},left=${left},top=${top}`);
  };

  return (
    <button className="linkedin-share-button" data-testid="linkedin-button" onClick={openWindow}>
      <div className="linkedin-button-logo-wrapper">
        <LinkedInLogo></LinkedInLogo>
      </div>
      <div className="linkedin-button-text">SHARE</div>
    </button>
  );
};

export default LinkedInSocialShareButton;
