import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { IonIcon } from "@ionic/react";
import { logoMicrosoft } from "ionicons/icons";

import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import CardPanel from "components/atom/card-panel/card-panel";
import { ReactComponent as Logo } from "assets/img/logos/logo-body-circle.svg";

import "./login.scss";

/**
 * MyNXU App Login page
 */
const Login = () => {
  const { instance } = useMsal();

  const [loginAttempt, setLoginAttempt] = useState(false);

  function signInClickHandler() {
    setLoginAttempt(true);
    instance.loginRedirect();
  }

  return (
    <PageContent>
      <section className="login">
        <div className="login__welcome-icon">
          <Logo aria-label="Welcome to Nexford MyNXU" />
        </div>
        <CardPanel className="login__cta-wrapper">
          <h1>Sign in to myNXU</h1>
          <NxuPrimaryButton
            className="login__cta"
            expand="block"
            disabled={loginAttempt}
            onClick={() => signInClickHandler()}
          >
            <IonIcon icon={logoMicrosoft} />
            Sign in
          </NxuPrimaryButton>
        </CardPanel>
      </section>
    </PageContent>
  );
};
export default Login;
