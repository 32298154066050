import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

import { COURSERA_DISCORD } from "constants/external-routes";
import { useCourseraProgram } from "utils/hooks/program";
import { AggregateLearnerProfile, ProgramProviderOptions } from "types/learner";

import courseraLogo from "assets/img/third-party/coursera-logo-blue.svg";
import thinkfulLogo from "assets/img/third-party/thinkful-logo-black.svg";

import { NxuAlert, NxuContentLoading, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import SecondaryButton from "components/atom/button-secondary";

import "./learner-external-card.scss";

interface ExternalPanelValues {
  type: string;
  link?: string;
  communityLink?: string;
  imgUrl: string;
}

const CourseraPanel: ExternalPanelValues = {
  type: "Coursera",
  communityLink: COURSERA_DISCORD,
  imgUrl: courseraLogo,
};

const ThinkfulPanel: ExternalPanelValues = {
  type: "Thinkful",
  link: process.env.REACT_APP_URL_THINKFUL || "",
  imgUrl: thinkfulLogo,
};

export interface LearnerExternalCardProps {
  learnerProfile?: AggregateLearnerProfile;
  isLoading?: boolean;
  error?: Error | null;
}

/**
 * Display details of the learner's
 */
const LearnerExternalCard = ({ learnerProfile, isLoading, error }: LearnerExternalCardProps) => {
  const { instance } = useMsal();

  const provider = learnerProfile?.provider;
  const programName = learnerProfile?.programName;
  const programDescription = learnerProfile?.programDescription;
  const programStartDate = learnerProfile?.programStartDate;
  const productType = learnerProfile?.productType || "";
  const productCode = learnerProfile?.productCode;

  const [panelValues, setPanelValues] = useState<ExternalPanelValues>();
  const [panelTitle, setPanelTitle] = useState<string>("");

  const [countdownInDays, setCountdownInDays] = useState<number>();
  const [programAvailable, setProgramAvailable] = useState<boolean>(false);

  const { data: courseraProgram, error: courseraError } = useCourseraProgram(
    programAvailable && !!productCode && !!productType,
    instance,
    productType || "",
    productCode || "",
  );

  useEffect(() => {
    if (provider === ProgramProviderOptions.COURSERA) {
      setPanelValues(CourseraPanel);
      setPanelTitle("Professional Certificate");
    } else if (provider === ProgramProviderOptions.THINKFUL) {
      setPanelValues(ThinkfulPanel);
      setPanelTitle(productType);
    }
  }, [provider, productType]);

  useEffect(() => {
    if (programStartDate) {
      const programDate = new Date(programStartDate);
      programDate.setHours(0, 0, 0, 0);
      const currentDate = new Date(Date.now());
      currentDate.setHours(0, 0, 0, 0);

      if (currentDate >= programDate) {
        setProgramAvailable(true);
        setCountdownInDays(undefined);
      } else {
        setProgramAvailable(false);
        const diffDays = Math.ceil(Math.abs(programDate.getTime() - currentDate.getTime()) / 86400000);
        setCountdownInDays(diffDays);
      }
    } else {
      setProgramAvailable(false);
      setCountdownInDays(undefined);
    }
  }, [programStartDate]);

  const CourseraLinks: React.FunctionComponent = () => {
    if (programStartDate) {
      return (
        <div>
          {countdownInDays && (
            <div className="learner-external__card-panel-countdown">
              Program starts {countdownInDays > 1 ? `in ${countdownInDays} days` : `tomorrow`}
            </div>
          )}

          {courseraError && <NxuAlert message={courseraError.message} />}

          {!!courseraProgram && (
            <div className="learner-external__card-panel-cta">
              <NxuPrimaryButton href={courseraProgram}>Start Learning</NxuPrimaryButton>
              <SecondaryButton href={CourseraPanel.communityLink}>Join the Community</SecondaryButton>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  if (isLoading) {
    return <NxuContentLoading />;
  }

  return (
    <section className="learner-external">
      {error && (
        <>
          <div className="learner-external__title">
            <h2>{panelTitle || productType}</h2>
          </div>
          <NxuAlert
            message={`We're having difficulty getting your program summary: ${error.message}. Please refresh the page and if the problem persists,
            contact support for help.`}
          />
        </>
      )}

      {!!provider && (
        <CardPanel className="learner-external__card-panel">
          <div className="learner-external__card-panel-title">
            <div>
              <img src={panelValues?.imgUrl} aria-hidden />
              <h3>{programName}</h3>
            </div>
            <span className="learner-external__card-panel-chip">{panelTitle || productType}</span>
          </div>
          <p className="learner-external__card-panel-desc">{programDescription}</p>

          {provider === ProgramProviderOptions.COURSERA ? (
            <CourseraLinks />
          ) : (
            <div className="learner-external__card-panel-cta">
              <NxuPrimaryButton href={panelValues?.link}>Go to {panelValues?.type}</NxuPrimaryButton>
            </div>
          )}
        </CardPanel>
      )}
    </section>
  );
};
export default LearnerExternalCard;
