import { ProgramEnrollmentStatusTypes } from "./learner";

// Payment Provider Options
export enum PPO {
  FLYWIRE = "flywire",
  FLUTTERWAVE = "flutterwave",
  EDUBANC = "edubanc",
  STRIPE = "stripe",
  PAYPAL = "paypal",
  TRANSFER = "transfer",
  PAYPAL_PAYMENT = "paypal-payment",
  TRANSFER_PAYMENT = "transfer-payment",
}

export type ProviderTypes =
  | typeof PPO.FLYWIRE
  | typeof PPO.FLUTTERWAVE
  | typeof PPO.EDUBANC
  | typeof PPO.STRIPE
  | typeof PPO.PAYPAL
  | typeof PPO.TRANSFER;
export type ProviderModalTypes = typeof PPO.PAYPAL_PAYMENT | typeof PPO.TRANSFER_PAYMENT;

export enum PaymentGroups {
  NGUnderPayment = "NGPaymentAdjustmentGroup",
}
export type PaymentGroupTypes = typeof PaymentGroups.NGUnderPayment;
// Configs for payment providers
export interface FlutterwaveConfig {
  PublicKey: string;
  CallbackId: string;
}
export interface EdubancConfig extends FlutterwaveConfig {
  SubaccountId: string;
  SubaccountName: string;
}

export interface PaypalConfig {
  clientId: string;
  currency: string;
  intent: string;
}

export interface PaypalCallback {
  Reference: string;
}

export interface FlywireConfig {
  env: string;
  recipientCode: string;
}

// Callbacks to payment providers
export interface FlywireCallback {
  Reference: string;
  Status: "success" | "pending" | "error";
  Amount: number;
  PaymentMethod: "online" | "bank_transfer";
  Nonce: string;
  Sig: string;
}

export interface FlutterwaveCallback {
  status: string;
  transaction_id: number;
  tx_ref: string;
  amount: number;
  currency: string;
}

// Currencies
export interface CurrencyProvider {
  CurrencyCode: string;
  Provider: ProviderTypes;
  AmountUSD: number;
  Amount: number;
  ExchangeRate: number;
}

// Tuition Request Details

export interface TuitionInvoice {
  actualAmount: number;
  amountDue: number;
  amountPaid: number;
  datePaid: string;
  discountApplied: number;
  dueDate: string;
  invoiceId: number;
  paymentPurpose: string;
  productFriendlyName?: string;
  period: string;
  refundedCredit: number;
  appliedCredit?: number;
  adjustedCredit?: number;
  status: string;
  subscriptionId?: string;
  isFoundation?: boolean;
}

export interface PaymentGroup {
  name: PaymentGroupTypes;
  adjustedBalance: number;
}

// returned by: api/billing/learners/self/tuition
export interface TuitionRequest {
  balance: number;
  hasOldInvoices: boolean;
  invoices: Array<TuitionInvoice>;
  learnerId: string;
  nextBillDate: string;
  paymentFrequency: number;
  totalAmountDue: number;
  productName: string;
  status: ProgramEnrollmentStatusTypes;
  paymentGroup: PaymentGroup | null;
}

export interface InvoiceLink {
  DownloadLink: string;
  LearnerId: string;
  ValidTill: string;
}

export interface TuitionCapProgress {
  maxCost: number;
  totalPaid: number;
  progressPercentage: number;
}

// Payment Form
export interface PaymentFormInputs {
  FirstName: string;
  LastName: string;
  Email: string;
  Street: string;
  City: string;
  PostalCode: string;
  SaveCard?: boolean | undefined;
}

export interface CardholderDetails {
  FirstName: string;
  LastName: string;
  Email: string;
  Street: string;
  City: string;
  PostalCode: string;
}

export interface PaymentProviders {
  Currencies: Array<CurrencyProvider>;
  CallbackId: string;
  FlywireConfig?: any;
  FlutterWaveConfig?: any;
  EdubancConfig?: any;
}

export interface FlutterwaveApplicationFeePayload {
  TransactionId: number;
  FlutterwaveReference: string;
  Status: string;
  Amount: number;
  Currency: string;
}

export interface StripeApplicationFeePayload {
  PaymentIntent: {
    Action: "Create" | "Confirm";
    PaymentMethodId?: string;
    PaymentIntentId?: string;
    SaveCard: boolean;
  };
  Amount: number;
}

export interface CreatePaypalOrderPayload {
  callbackId: string;
  amount: number;
  usdAmount: number;
  coupon: string | null;
  currencyCode: string;
  learnerId: string;
  reference: string;
}

export interface PaypalOrderResponse {
  orderId: string;
  status: string;
}
