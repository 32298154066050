import { cashOutline, homeOutline, personCircleOutline, schoolOutline } from "ionicons/icons";
import { LocalRoutes } from "./routes";

interface NavItemType {
  label: string;
  icon: string;
  path: string;
}

const HomeNavItem: NavItemType = {
  label: "Home",
  icon: homeOutline,
  path: LocalRoutes.HOME,
};

const ProgramNavItem: NavItemType = {
  label: "Program",
  icon: schoolOutline,
  path: LocalRoutes.ACADEMICS,
};

const WalletNavItem: NavItemType = {
  label: "Wallet",
  icon: cashOutline,
  path: LocalRoutes.WALLET,
};

const ProfileNavItem: NavItemType = {
  label: "Profile",
  icon: personCircleOutline,
  path: LocalRoutes.PROFILE,
};

// Nav items to be displayed in the footer on a mobile device
export const mobileFooterNavItems: Array<NavItemType> = [HomeNavItem, ProgramNavItem, WalletNavItem];

// Nav items to be displayed in the menu of a desktop screen below 768px w
export const desktopMenuNavItems: Array<NavItemType> = [HomeNavItem, ProgramNavItem, WalletNavItem, ProfileNavItem];

// Nav items to be displayed in the header of a desktop screen above 768px w
export const desktopHeaderNavItems = [ProgramNavItem, WalletNavItem, ProfileNavItem];
