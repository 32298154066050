import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { apiGet } from "utils/hooks/api-client";
import { useAuthContext } from "./Auth";

export interface StripeContextType {
  loading: boolean;
  stripeApiKey: string;
  stripeLoadingError?: string;
}

const initialStripeState: StripeContextType = {
  loading: false,
  stripeApiKey: "",
  stripeLoadingError: "",
};

export interface StripeProviderProps {
  children?: React.ReactNode;
  providedState?: StripeContextType;
}

/**
 * Get and Store the Stripe API key from the server for use throughout Campus UI
 */
const StripeContext = React.createContext<StripeContextType>({} as StripeContextType);

export function StripeProvider(props: StripeProviderProps) {
  const { getBearerToken, userAccount } = useAuthContext();
  const { children, providedState } = props;

  const state = useMemo(() => ({ ...initialStripeState, ...providedState }), [providedState]);

  const [loading, setLoading] = useState<boolean>(state.loading);
  const [stripeApiKey, setStripeApiKey] = useState<string>(state.stripeApiKey);
  const [stripeLoadingError, setStripeLoadingError] = useState<string | undefined>(state.stripeLoadingError);

  const loadStripeApiKey = useCallback(async () => {
    setLoading(true);
    // ToDo: This should absolutely be living in its own helper file, without the need to pass in the bearer token manually
    apiGet("/api/billing/config/stripe")
      .then((response) => setStripeApiKey(response.PublishableKey))
      .catch((reason: string) => setStripeLoadingError(reason))
      .finally(() => setLoading(false));
  }, [setLoading, setStripeApiKey, setStripeLoadingError, getBearerToken]);

  useEffect(() => {
    if (!loading && !stripeApiKey && userAccount) {
      loadStripeApiKey();
    }
  }, [loading, stripeApiKey, loadStripeApiKey, userAccount]);

  const value = {
    loading,
    stripeApiKey,
    stripeLoadingError,
  };

  return <StripeContext.Provider value={value}>{children}</StripeContext.Provider>;
}

export const useStripeContext = () => useContext(StripeContext);
