import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { LocalRoutes } from "constants/routes";

import { useAuthContext } from "utils/context/Auth";
import { useLearner } from "utils/hooks/learners";

// ToDo: Switch to an analytics wrapper if we go beyond just tracking page views
declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export interface InitialisationContextType {
  initialisingCampus: boolean;
  initialisedCampus: boolean;
  initialisedCampusError: string | null;
  readmissionRequired: boolean;
  agreementAcceptance: "accepted" | "rejected" | null;
}

// The default state values to be used by Provider
const defaultInitialisationState: InitialisationContextType = {
  initialisingCampus: true,
  initialisedCampus: false,
  initialisedCampusError: "",
  readmissionRequired: false,
  agreementAcceptance: null,
};

export interface InitialisationProviderProps {
  children?: React.ReactNode;
  providedState?: InitialisationContextType;
}

export const InitialisationContext = React.createContext<any>({} as any);

/**
 * Run through initial checks and redirects on MyNXU once logged in
 */
export function InitialisationProvider(props: InitialisationProviderProps) {
  const { userAccount } = useAuthContext();
  const { children, providedState } = props;
  // Generate the providers initial values based on default set plus any passed in through props
  const initialState = useMemo(() => ({ ...defaultInitialisationState, ...providedState }), [providedState]);

  const { instance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();

  const [initialisingCampus, setInitialisingCampus] = useState(initialState.initialisingCampus);
  const [initialisedCampus, setInitialisedCampus] = useState(initialState.initialisedCampus);
  const [initialisedCampusError, setInitialisedCampusError] = useState(initialState.initialisedCampusError);

  const [readmissionRequired, setReadmissionRequired] = useState(initialState.readmissionRequired);
  const [agreementAcceptance, setAgreementAcceptance] = useState(initialState.agreementAcceptance);

  const {
    data: aggregateLearnerProfile,
    isLoading: learnerLoading,
    error: learnerError,
  } = useLearner(!!userAccount, instance);

  const initialiseCampus = useCallback(async () => {
    if (aggregateLearnerProfile) {
      // const isQa = process.env.NODE_ENV === "development";
      setReadmissionRequired(aggregateLearnerProfile.unsignedAgreementPending);
      setAgreementAcceptance(null);
      setInitialisedCampusError("");
      sessionStorage.setItem("learnerProfile", JSON.stringify(aggregateLearnerProfile));
      window.dataLayer.push({ learnerId: aggregateLearnerProfile.learnerId });

      if (aggregateLearnerProfile.unsignedAgreementPending && !agreementAcceptance) {
        navigate(LocalRoutes.ENROLL);
      }

      setInitialisedCampus(true);
      setInitialisingCampus(false);
    }
  }, [aggregateLearnerProfile, agreementAcceptance, navigate]);

  const handleInitialisationError = useCallback(async () => {
    if (learnerError) {
      if (learnerError.cause === 401) instance.logoutRedirect();
      setInitialisedCampusError(learnerError.message);
      setInitialisingCampus(false);
      setInitialisedCampus(true);
    }
  }, [instance, learnerError]);

  useEffect(() => {
    if (learnerError) handleInitialisationError();
  }, [handleInitialisationError, learnerError]);

  useEffect(() => {
    if (userAccount && !initialisedCampus && aggregateLearnerProfile && !learnerLoading) {
      initialiseCampus();
    }
  }, [userAccount, initialiseCampus, initialisedCampus, aggregateLearnerProfile, learnerLoading]);

  useEffect(() => {
    if (window?.dataLayer?.push) {
      window.dataLayer.push({
        event: "pageview",
        page: location.pathname,
      });
    }
  }, [location.pathname]);

  const value = {
    readmissionRequired,
    setReadmissionRequired,
    agreementAcceptance,
    setAgreementAcceptance,
    initialisingCampus,
    initialisedCampus,
    initialisedCampusError,
  };

  return <InitialisationContext.Provider value={value}>{children}</InitialisationContext.Provider>;
}

export const useInitialisationContext = () => useContext(InitialisationContext);
