import React from "react";
import cx from "classnames";
// Components
import { NxuComponentLoading } from "@nexford/nexford-ui-component-library";
import GetInTouch from "components/atom/get-in-touch";
import DocumentViewer from "components/molecule/document-viewer";
import AgreementDocHeader from "../agreement-doc-header";

// Styles
import "../agreement.scss";

interface SapPlanProps {
  displaySapPlan: boolean;
  sapFile?: string;
  sapUrl?: string;
  sapDocumentLoading: boolean;
  toggleSapPlanDisplay: () => void;
}

/**
 * Display the Satisfactory Academic Performance (SAP) Plan on the Agreement Page
 */
const SapPlan = (props: SapPlanProps) => {
  const { displaySapPlan, sapFile, sapUrl, sapDocumentLoading, toggleSapPlanDisplay } = props;

  return (
    <div className={cx("agreement__wrapper", !displaySapPlan && "agreement__wrapper--hidden")}>
      <AgreementDocHeader
        welcomeMsg="Your Satisfactory Academic Performance Plan"
        hasFile={!!sapFile}
        file={sapFile as string}
        fileTitle="SatisfactoryAcademicPerformancePlan.pdf"
        hasToggle={true}
        isEnrollmentOpen={!displaySapPlan}
        toggleEvent={() => toggleSapPlanDisplay()}
      />
      <div className="agreement__document">
        {sapDocumentLoading && <NxuComponentLoading />}
        {!sapDocumentLoading && sapUrl && sapFile && (
          <div>
            <DocumentViewer
              file={sapFile}
              url={sapUrl}
              fileIsPdf={true}
              frameTitle="Satisfactory Academic Performance Plan"
            />
          </div>
        )}
      </div>

      {!sapDocumentLoading && sapUrl && sapFile && (
        <GetInTouch type="success">
          <>
            <p>Review the academic criteria for your new enrollment, and then continue to sign your agreement.</p>
            <p>Questions? Get in touch and we'll help.</p>
          </>
        </GetInTouch>
      )}
    </div>
  );
};

export default SapPlan;
