import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonButton,
  IonToggle,
  IonLabel,
} from "@ionic/react";
import { logOutOutline, personCircleOutline } from "ionicons/icons";

// Consts
import { LocalRoutes } from "constants/routes";
import { testEnvironment } from "constants/feature-flags";
import { isMobileOS } from "utils/detect-device";
import { signOutClickHandler } from "utils/profile-utils";
import { desktopHeaderNavItems } from "constants/navigation";
import { ReactComponent as LogoImg } from "assets/img/logos/logo-titlebar.svg";

// Styles
import "./header.scss";

const Header = (props: { isNavHidden?: boolean }) => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const storedTestFlag = localStorage.getItem("qaTestFlag");

  const setTestFlag = (e: any) => {
    if (testEnvironment) {
      localStorage.setItem("qaTestFlag", e.detail.checked);
      window.location.reload();
    }
  };

  return (
    <IonHeader mode="md" data-testid="nxu-header">
      <IonToolbar className="header__titlebar">
        <IonTitle slot="start">
          {!props.isNavHidden && (
            <Link to={LocalRoutes.HOME} className="header-logo-wrapper">
              <LogoImg aria-label="Go to myNXU home" className="header-logo" />
            </Link>
          )}
          {props.isNavHidden && (
            <div className="header-logo-wrapper">
              <LogoImg aria-label="Nexford University myNXU" className="header-logo" />
            </div>
          )}
        </IonTitle>

        <IonButtons slot="end">
          {testEnvironment && (
            <IonToggle
              className="test-flag-mask header__test-flag"
              labelPlacement="stacked"
              checked={storedTestFlag === "true"}
              onIonChange={(e) => setTestFlag(e)}
            >
              QA Mode
            </IonToggle>
          )}

          {isMobileOS() && (
            <>
              {!props.isNavHidden && (
                <IonButton
                  className="nxu-header__profile-button"
                  color="dark"
                  aria-label="Go to Profile"
                  size="large"
                  shape="round"
                  onClick={() => navigate(LocalRoutes.PROFILE)}
                >
                  <IonIcon slot="icon-only" icon={personCircleOutline}></IonIcon>
                </IonButton>
              )}
              {props.isNavHidden && (
                <IonButton
                  className="nxu-header__profile-button"
                  data-testid="nxu-header-nav-logout"
                  color="danger"
                  aria-label="Log out"
                  size="large"
                  shape="round"
                  onClick={() => signOutClickHandler(instance)}
                >
                  <IonIcon icon={logOutOutline}></IonIcon>
                </IonButton>
              )}
            </>
          )}

          {!isMobileOS() && !props.isNavHidden && (
            <>
              <IonMenuButton />
              {desktopHeaderNavItems.map((item) => (
                <Link to={item.path} className="nxu-header__desktop-nav-button" key={`desktop-nav-${item.label}`}>
                  <IonIcon icon={item.icon} />
                  <IonLabel>{item.label}</IonLabel>
                </Link>
              ))}
              <button className="nxu-header__desktop-nav-button" onClick={() => signOutClickHandler(instance)}>
                <IonIcon icon={logOutOutline}></IonIcon>
                <IonLabel>Logout</IonLabel>
              </button>
            </>
          )}
          {!isMobileOS() && props.isNavHidden && (
            <button className="nxu-header__desktop-nav-button" onClick={() => signOutClickHandler(instance)}>
              <IonIcon icon={logOutOutline}></IonIcon>
              <IonLabel>Logout</IonLabel>
            </button>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
