import React from "react";

import CardPanel from "components/atom/card-panel";
import GetInTouch from "components/atom/get-in-touch";
import { PageContent } from "components/molecule/page-wrap/page-wrap";

import "./error-pages.scss";

/**
 * Standard 503 page
 */
const Maintenance = () => (
  <PageContent>
    <CardPanel className="error-page">
      <h1>myNXU is down for maintenance</h1>
      <p>Sorry for any inconvenience, we will be back up and running as soon as possible.</p>
    </CardPanel>
    <GetInTouch />
  </PageContent>
);

export default Maintenance;
