import React from "react";

import "./auth-errored.scss";

/**
 * Standard Error screen to be shown on authentication errors
 */
const AuthErrored = () => (
  <div className="auth-errored">
    <pre>
      <code>Authentication Error</code>
      <code>There was an error on authentication, please refresh the page and try again</code>
    </pre>
  </div>
);

export default AuthErrored;
