import React, { useEffect } from "react";

import { FlywireCallback, FlywireConfig } from "types/wallet";
import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";

// ToDo: Global types declaration for window
declare const window: any;

interface FlywireProps {
  onOpenFlywire: () => void;
  onSubmit: (arg0: FlywireCallback) => void;
  onCancel: () => void;
  submitting?: boolean;
  paymentIsOpen: boolean;
  config: FlywireConfig;
}

/**
 * Payment interface component for Flywire
 */
export default function Flywire({
  onOpenFlywire,
  onSubmit,
  onCancel,
  paymentIsOpen,
  submitting,
  config,
}: FlywireProps) {
  // Configure and open the flywire checkout
  const handleFlywirePay = () => {
    onOpenFlywire();

    const updateConfig = {
      ...config,
      onInvalidInput(errors: any[]) {
        errors.forEach((error) => {
          console.error(error);
        });
      },
      onCancel,
      onCompleteCallback(args: {
        reference: string;
        status: FlywireCallback["Status"];
        amount: number;
        paymentMethod: FlywireCallback["PaymentMethod"];
        _: string;
        sig: string;
      }) {
        const data: FlywireCallback = {
          Reference: args.reference,
          Status: args.status,
          Amount: args.amount,
          PaymentMethod: args.paymentMethod,
          Nonce: args._,
          Sig: args.sig,
        };

        onSubmit(data);
      },
    };
    const modal = window.FlywirePayment.initiate(updateConfig);
    modal.render();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.flywire.com/flywire-payment.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <NxuPrimaryButton disabled={submitting || paymentIsOpen} onClick={handleFlywirePay}>
      {submitting || paymentIsOpen ? "In progress" : "Continue"}
    </NxuPrimaryButton>
  );
}
