import { NxuAlert, NxuComponentLoading } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import "./documents-panel.scss";

export interface DocumentsPanelProps {
  children: React.ReactNode;
  title: string;
  documentStatusLoading: boolean;
  documentStatusError: Error | null;
}

const DocumentsPanel = ({ title, children, documentStatusError, documentStatusLoading }: DocumentsPanelProps) => (
  <div className="documents-panel" data-testid="documents-panel">
    <CardPanel>
      <h2>{title}</h2>
      {documentStatusLoading && <NxuComponentLoading testId="documents-panel-loading" />}
      {documentStatusError && <NxuAlert message={documentStatusError.message} />}

      <div className="documents-list">{children}</div>
    </CardPanel>
  </div>
);

export default DocumentsPanel;
