import React, { useEffect, useRef } from "react";
import cx from "classnames";
import lottie from "lottie-web";

import loadingLottie from "assets/animation/interstitial-loading.json";

import "./mynxu-animation.scss";

type AnimationOptions = "loading";

const animationOptions: { [key: string]: any } = {
  // Current loading asset is a placeholder, waiting for a new version from product to be included in #6928
  loading: loadingLottie,
};

export interface MyNxuAnimationProps {
  animation: AnimationOptions;
  className?: string;
}

/**
 * Render Lottie animation files
 */
const MyNxuAnimation = ({ animation, className }: MyNxuAnimationProps) => {
  const animationAsset = animationOptions[animation];
  const animationEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!animationAsset && animationEl.current) {
      lottie.loadAnimation({
        container: animationEl.current,
        animationData: animationAsset,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });
    }
  }, [animationAsset, animationEl]);

  return (
    <div
      data-testid={`animation__${animation}`}
      className={cx("mynxu-animation__wrapper", className)}
      ref={animationEl}
    />
  );
};

export default MyNxuAnimation;
