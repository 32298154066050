import { useMutation, useQuery } from "@tanstack/react-query";
import { IPublicClientApplication } from "@azure/msal-browser";

import {
  CreatePaypalOrderPayload,
  FlutterwaveApplicationFeePayload,
  InvoiceLink,
  PaymentProviders,
  PaypalOrderResponse,
  StripeApplicationFeePayload,
  TuitionCapProgress,
  TuitionRequest,
} from "types/wallet";

import { apiGet, apiPost } from "./api-client";

export const walletErrMsg = {
  fetchTuitionInfo:
    "Uh-oh! There was an error on getting your wallet history. Please refresh and try again or contact support.",
  refetchTuitionInfo:
    "Uh-oh! There was an error on getting your latest wallet history. Please refresh and if the error continues, contact support.",
  fetchProvidersOptions:
    "Uh-oh! There was an error on getting our payment options. Please refresh and try again or contact support.",
  completeFlutterwavePayment:
    "Uh-oh! There was an error on completing the payment with Flutterwave. Please contact support to confirm the status of the payment.",
  completeStripePayment:
    "Uh-oh! There was an error on completing the payment with card. Please contact support to confirm the status of the payment.",
  downloadInvoice:
    "Uh-oh! There was an error on attempting to retrieve the full invoice. Please refresh and try again or contact support.",
  tuitionCap:
    "Uh-oh! There was an error on attempting to retrieve the tuition progress. Please refresh and try again or contact support.",
};

// Get tuition costs and invoice history
const fetchTuitionInfo = async (msalInstance: IPublicClientApplication): Promise<TuitionRequest> =>
  apiGet(`/api/mynxuaggregator/self/tuition`, walletErrMsg.fetchTuitionInfo, msalInstance);

const useTuitionInfo = (msalInstance: IPublicClientApplication) =>
  useQuery({
    queryKey: ["tuition-info"],
    queryFn: () => fetchTuitionInfo(msalInstance),
    throwOnError: false,
    retry: 3,
  });

// Get link to Invoice PDF from chargebee
const fetchInvoiceLink = async (msalInstance: IPublicClientApplication, invoiceId?: number): Promise<InvoiceLink> =>
  apiGet(`/api/billing/learners/self/invoices/${invoiceId}/pdf`, walletErrMsg.downloadInvoice, msalInstance);

const useInvoiceLink = (msalInstance: IPublicClientApplication, invoiceId?: number) =>
  useQuery({
    queryKey: ["invoice-link", invoiceId],
    queryFn: () => fetchInvoiceLink(msalInstance, invoiceId),
    throwOnError: false,
    enabled: !!invoiceId,
    retry: 3,
  });

const fetchTuitionCap = async (
  msalInstance: IPublicClientApplication,
  programEnrollmentId?: string,
): Promise<TuitionCapProgress> =>
  apiGet(
    `/api/mynxuaggregator/self/program-enrollments/${programEnrollmentId}/payment-progress`,
    walletErrMsg.tuitionCap,
    msalInstance,
  );

const useTuitionCap = (msalInstance: IPublicClientApplication, programEnrollmentId?: string, isTestEnv?: boolean) =>
  useQuery({
    queryKey: ["tuition-cap"],
    queryFn: () => fetchTuitionCap(msalInstance, programEnrollmentId),
    throwOnError: false,
    enabled: !!programEnrollmentId && isTestEnv,
    retry: 3,
  });

// Get payment options available to the learner
const fetchProvidersOptions = async (
  msalInstance: IPublicClientApplication,
  amount: number,
): Promise<PaymentProviders> =>
  apiGet(
    `/api/billing/learners/self/payment-providers?amount=${amount}`,
    walletErrMsg.fetchProvidersOptions,
    msalInstance,
  );

const useProvidersOptions = (msalInstance: IPublicClientApplication, amount: number) =>
  useQuery({
    queryKey: ["payment-proividers-list", amount],
    queryFn: () => fetchProvidersOptions(msalInstance, amount),
    throwOnError: false,
    // Payment providers includes a unique callback ID that is refreshed with each request
    // Set enabled to false to block any automated fetches, and only allow this to be triggered manually
    enabled: false,
    retry: 3,
  });

// Post stripe payment to API to be passed to Chargebee
const useApplicationFeeStripe = () =>
  useMutation({
    mutationFn: (prop: { payload: StripeApplicationFeePayload; msalInstance: IPublicClientApplication }) =>
      apiPost(
        "/api/billing/learners/self/payment/stripe",
        prop.payload,
        walletErrMsg.completeStripePayment,
        prop.msalInstance,
      ),
  });

// Post flutterwave payment to API to be passed to Chargebee
const usePayApplicationFeeFlutterwave = () =>
  useMutation({
    mutationFn: (prop: { payload: FlutterwaveApplicationFeePayload; msalInstance: IPublicClientApplication }) =>
      apiPost(
        "/api/billing/learners/self/payment/flutterwave",
        prop.payload,
        walletErrMsg.completeFlutterwavePayment,
        prop.msalInstance,
      ),
  });

const useCreatePaypalOrder = () =>
  useMutation({
    mutationFn: (payload: CreatePaypalOrderPayload): Promise<PaypalOrderResponse> =>
      apiPost("/api/paypal/create-order", payload),
  });

const useCapturePaypalOrder = () =>
  useMutation({
    mutationFn: (orderId: string): Promise<PaypalOrderResponse> => apiPost(`/api/paypal/capture-order/${orderId}`, {}),
  });

export {
  useTuitionInfo,
  useInvoiceLink,
  useTuitionCap,
  useProvidersOptions,
  useApplicationFeeStripe,
  usePayApplicationFeeFlutterwave,
  useCreatePaypalOrder,
  useCapturePaypalOrder,
};
