import { IonIcon } from "@ionic/react";
import { alertCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
import { AepDocument, AepDocumentStatusOptions } from "types/learner";
import {
  getAepDocumentDestinationMail,
  getAepDocumentHelpText,
  getAepDocumentLabel as getAepDocumentNameLabel,
  getAepUrlFAQ,
} from "utils/learner-utils";
import cx from "classnames";
import "./aep-document-item.scss";

export interface AepDocumentItemProps {
  aepDocument: AepDocument;
}

const AepDocumentItem = ({ aepDocument }: AepDocumentItemProps) => {
  const name = getAepDocumentNameLabel(aepDocument.name);
  const helperText = getAepDocumentHelpText(aepDocument.name);
  const destinationEmailAddress = getAepDocumentDestinationMail(aepDocument.name);
  const faqUrl = getAepUrlFAQ(aepDocument.name);
  const isApproved = aepDocument.status === AepDocumentStatusOptions.ok;
  const icon = isApproved ? checkmarkCircleOutline : alertCircleOutline;

  return (
    <div key={`aep-document-item-${aepDocument.name}`} className="aep-document-item">
      <div className="aep-document-item__column">
        <IonIcon
          className={cx(`aep-document-item__status-icon aep-document-item__status-icon--${aepDocument.status}`)}
          icon={icon}
        />
        <div className="aep-document-item__details">
          <span className="aep-document-item__details-title">{name}</span>
          {!isApproved && (
            <span data-testid="aep-helper-text" className="aep-document-item__description">
              {helperText} <strong>{destinationEmailAddress}</strong>
            </span>
          )}
          {!isApproved && faqUrl && (
            <a href={faqUrl} target="_blank">
              More info
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default AepDocumentItem;
