import React from "react";
import { IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter } from "@ionic/react";
import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import { LearningPathCourse } from "types/learning-path";
import { NEXFORD_CANVAS_COURSE } from "constants/external-routes";

import "./learner-path-modal.scss";

export interface LearnerPathModalProps {
  course: LearningPathCourse | null;
  isOpen: boolean;
  closeModal: () => void;
}

/**
 * Display an info modal for course descriptions on the learner path
 */
const LearnerPathModal = (props: LearnerPathModalProps) => {
  const { course, isOpen, closeModal } = props;

  const skillsTitle = course?.substatus === "passed" ? "Skills you've gained" : "Skills you'll gain";
  const handleClose = () => {
    closeModal();
  };

  return (
    <IonModal
      data-testid="learner-path-modal"
      isOpen={isOpen && !!course}
      onDidDismiss={handleClose}
      className="nxu-modal learner-path-modal"
    >
      <IonHeader className="learner-path-modal__header">
        <IonToolbar>
          <IonTitle data-testid="learner-path-modal-title">{course?.courseCode}</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="outline" onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="learner-path-modal__content">
        <div className="learner-path-modal__content-inner">
          <h2>{course?.courseName}</h2>
          <div className="learner-path-modal__description">
            <h3>Course Description</h3>
            <p>{course?.courseDescription}</p>
          </div>
          {!!course?.skills?.length && (
            <div className="learner-path-modal__skills">
              <h3>{skillsTitle}</h3>
              <div className="skills-list" data-testid="skills-list">
                {course?.skills?.map((item) => (
                  <span key={`${item}-key`} className="skills-list__item">
                    {item}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </IonContent>

      <IonFooter className="learner-path-modal__footer">
        <IonToolbar>
          {course?.startDate && (
            <NxuPrimaryButton
              href={`${NEXFORD_CANVAS_COURSE}/${course.courseCode}/${course.startDate}`}
              target="_blank"
            >
              Go to Course
            </NxuPrimaryButton>
          )}
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default LearnerPathModal;
