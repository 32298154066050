import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons, PayPalButtonsComponentProps } from "@paypal/react-paypal-js";

import config from "config";
import { CreatePaypalOrderPayload, CurrencyProvider, PaypalConfig } from "types/wallet";
import { useCreatePaypalOrder, useCapturePaypalOrder } from "utils/hooks/wallet";
import { NxuAlert } from "@nexford/nexford-ui-component-library";

interface PaypalFormProps {
  onSubmit: () => void;
  onCancel: () => void;
  callbackId: string;
  paypalProvider: CurrencyProvider;
  submitting?: boolean;
}

/**
 * Payment interface component for Paypal
 */
export default function PaypalForm({ onSubmit, onCancel, submitting, paypalProvider, callbackId }: PaypalFormProps) {
  const [paymentIsActive, setPaymentIsActive] = useState(false);
  const [paypalError, setPaypalError] = useState<string | null>(null);

  const [paypalConfigOptions] = useState<PaypalConfig>({
    clientId: config.paypal.clientId,
    currency: paypalProvider.CurrencyCode || "USD",
    intent: "capture",
  });

  const createPaypalOrderRequest = useCreatePaypalOrder();
  const capturePaypalOrderRequest = useCapturePaypalOrder();

  const createOrder: PayPalButtonsComponentProps["createOrder"] = async () => {
    try {
      setPaymentIsActive(true);
      const refIds = callbackId.split("/");
      const createOrderPayload: CreatePaypalOrderPayload = {
        callbackId,
        amount: paypalProvider.Amount || 0,
        usdAmount: paypalProvider.AmountUSD || 0,
        coupon: null,
        currencyCode: paypalProvider.CurrencyCode || "USD",
        learnerId: refIds[0] || "",
        reference: refIds[1] || "",
      };
      const createOrderResponse = await createPaypalOrderRequest.mutateAsync(createOrderPayload);
      if (!createOrderResponse.orderId) throw new Error();

      return createOrderResponse.orderId;
    } catch (error) {
      setPaypalError(
        "There was an error on proceeding with your payment. Please refresh and try again or contact support.",
      );
      setPaymentIsActive(false);
      throw error;
    }
  };

  const onApprove: PayPalButtonsComponentProps["onApprove"] = async (data) => {
    try {
      await capturePaypalOrderRequest.mutateAsync(data.orderID);
      onSubmit();
    } catch (error) {
      setPaypalError(
        "There was an error on completing your payment through Paypal. Please contact support to confirm the status of the payment.",
      );
      setPaymentIsActive(false);
    }
  };

  const handleError: PayPalButtonsComponentProps["onError"] = (err) => {
    console.warn(err);
    setPaypalError(
      "There was an error on completing your payment through Paypal. Please try again or contact support.",
    );
    setPaymentIsActive(false);
  };

  const cancelPaypal: PayPalButtonsComponentProps["onCancel"] = () => {
    setPaymentIsActive(false);
    onCancel();
  };

  return (
    <>
      {!!paypalError && (
        <div className="stripe-card__error">
          <NxuAlert fullWidth message={paypalError} />
        </div>
      )}
      <PayPalScriptProvider options={paypalConfigOptions}>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          onError={handleError}
          onCancel={cancelPaypal}
          disabled={submitting || paymentIsActive}
          style={{ label: "pay", tagline: false, disableMaxWidth: true, layout: "horizontal" }}
        />
      </PayPalScriptProvider>
      {submitting && (
        <NxuAlert
          type="progress"
          message="Your payment is in progress. Please do not refresh or leave the current page."
        />
      )}
    </>
  );
}
