export enum CourseEnrollmentStatus {
  Enrolled = "enrolled",
  Completed = "completed",
}

export enum CourseEnrollmentSubStatus {
  InProgress = "enrolled",
  Preview = "preview",
  Previewing = "previewing",
  Passed = "passed",
  Failed = "failed",
}

export type CourseEnrollmentStatusTypes =
  | typeof CourseEnrollmentStatus.Enrolled
  | typeof CourseEnrollmentStatus.Completed;

export type CourseEnrollmentSubStatusTypes =
  | typeof CourseEnrollmentSubStatus.Failed
  | typeof CourseEnrollmentSubStatus.InProgress
  | typeof CourseEnrollmentSubStatus.Preview
  | typeof CourseEnrollmentSubStatus.Previewing
  | typeof CourseEnrollmentSubStatus.Passed;

export interface CoursesItem {
  id: string;
  title: string;
  href: string;
}

export interface DeadlinesItem {
  id: string;
  date: string;
  type: "invoice" | "module";
  title: string;
  href?: string;
}

export interface LearningPathCourse {
  courseCode: string;
  courseName: string;
  courseDescription: string;
  grade: string | null;
  isExternalTransfer: boolean;
  isInProgress: boolean;
  isSeminar: boolean;
  nextAvailableAt?: string;
  order: number;
  retaken: number;
  startDate?: string;
  status: CourseEnrollmentStatusTypes | null;
  substatus: CourseEnrollmentSubStatusTypes | null;
  skills: Array<string> | null;
}

export type LearningPathList = Array<LearningPathCourse>;
