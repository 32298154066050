import React from "react";
import cx from "classnames";
import { IonIcon } from "@ionic/react";
import { documentLockOutline, fileTrayFullOutline, timeOutline } from "ionicons/icons";
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";

import "react-circular-progressbar/dist/styles.css";

import { NxuCardPanel, NxuComponentLoading, NxuAlert } from "@nexford/nexford-ui-component-library";

import { useAuthContext } from "utils/context/Auth";
import { useDeadlines } from "utils/hooks/deadlines";
import { DeadlineItem, DeadlineTypeOptions } from "types/learner";
import { deadlineDate } from "utils/format-date.utils";
import { LocalRoutes } from "constants/routes";

import "./deadlines-card.scss";

/**
 * Display the upcoming deadlines for the user
 */
const DeadlinesCard = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();

  const {
    data: aggregateDeadlines,
    isLoading: aggregateDeadlinesLoading,
    error: aggregateDeadlinesError,
  } = useDeadlines(!!userAccount, instance);

  const DeadlineRow = (item: DeadlineItem) => {
    const readableDate = deadlineDate(item.deadline);

    switch (item.deadlineType) {
      case DeadlineTypeOptions.privacy:
        return (
          <li className={cx("deadlines-list-item", item.isDue && "deadlines-list-item--due")}>
            <Link to={LocalRoutes.PRIVACY}>
              <span className="deadlines-list-item__label">
                <IonIcon icon={documentLockOutline} />
                <span>
                  {item.isDue && <span className="deadlines-list-item__label--overdue">Overdue</span>}
                  <span>Complete your privacy details</span>
                </span>
              </span>
              <span className="deadlines-list-item__date">{readableDate}</span>
            </Link>
          </li>
        );
      case DeadlineTypeOptions.aep:
        return (
          <li className={cx("deadlines-list-item", item.isDue && "deadlines-list-item--due")}>
            <Link to={LocalRoutes.DOCUMENTS}>
              <span className="deadlines-list-item__label">
                <IonIcon icon={fileTrayFullOutline} />
                <span>
                  {item.isDue && <span className="deadlines-list-item__label--overdue">Overdue</span>}
                  <span>Finish uploading your admission documents</span>
                </span>
              </span>
              <span className="deadlines-list-item__date">{readableDate}</span>
            </Link>
          </li>
        );
      default:
        return (
          <li className={cx("deadlines-list-item", item.isDue && "deadlines-list-item--due")}>
            <div>
              <span className="deadlines-list-item__label">
                <IonIcon icon={timeOutline} />
                <span>
                  {item.isDue && <span className="deadlines-list-item__label--overdue">Overdue</span>}
                  <span>{item.deadlineType}</span>
                </span>
              </span>
              <span className="deadlines-list-item__date">{readableDate}</span>
            </div>
          </li>
        );
    }
  };

  const DeadlinesList = () => {
    if (!aggregateDeadlines) return null;
    if (!!aggregateDeadlines && !aggregateDeadlines?.length) return <p>All your deadlines are completed</p>;

    return (
      <ul className="deadlines-list">
        {aggregateDeadlines.map((item, index) => {
          if (index > 4) return null;
          return <DeadlineRow {...item} />;
        })}
      </ul>
    );
  };

  if (aggregateDeadlinesLoading) {
    return (
      <NxuCardPanel className="deadlines-card" testId="deadlines-card">
        <NxuComponentLoading />
      </NxuCardPanel>
    );
  }

  return (
    <NxuCardPanel className="deadlines-card" testId="deadlines-card">
      <div className="deadlines-card__title-row">
        <h2>Deadlines</h2>
      </div>
      <DeadlinesList />
      {!!aggregateDeadlinesError && <NxuAlert fullWidth message={aggregateDeadlinesError.message} />}
    </NxuCardPanel>
  );
};
export default DeadlinesCard;
