import { JestAxeConfigureOptions } from "jest-axe";
import type React from "react";

// Configuration for Axe
export const axeConfig = {
  rules: [
    {
      id: "aria-allowed-attr",
      enabled: false,
    },
  ],
};

// Annoyingly, axe-jest uses a different config format,
// Any configured rules will need to be duplicated here
export const axeJestConfig: JestAxeConfigureOptions = {
  rules: {
    "aria-allowed-attr": { enabled: false },
  },
};

// Wrapper applied to index.tsx to initialise axe on the app
export const reportAccessibility = async (App: typeof React): Promise<void> => {
  if (process.env.NODE_ENV !== "production") {
    const axe = await import("@axe-core/react");
    const ReactDOM = await import("react-dom");

    axe.default(App, ReactDOM, 1000, axeConfig);
  }
};

export default reportAccessibility;
