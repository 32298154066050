import { saveAs } from "file-saver";

export function b64toBlob(b64Data: string, contentType: string, sliceSize: number): Blob {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function downloadFile(b64File: string, name: string) {
  if (b64File) {
    const blob = b64toBlob(b64File, "application/pdf", 512);
    saveAs(blob, name);
  }
}
