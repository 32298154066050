// eslint-disable-next-line import/prefer-default-export
export function getMobileOperatingSystem() {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  // Android detection
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export function isMobileOS() {
  // @ts-ignore
  const mobileOperatingSystem = getMobileOperatingSystem();
  return mobileOperatingSystem !== "unknown";
}
