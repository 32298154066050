import React from "react";
import ReactDOM from "react-dom/client";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";

import reportAccessibility from "utils/reportAccessibility";
import { LocalRoutes } from "constants/routes";
import config from "config";
import App from "./app";
import * as serviceWorker from "./serviceWorker";

/**
 * Object declaration for MSAL auth initialisation
 */
const msalInstance = new PublicClientApplication({
  auth: {
    authority: `https://login.microsoftonline.com/${config.authentication.tenantId}`,
    clientId: config.authentication.clientId,
    redirectUri: `${window.location.origin}`,
    postLogoutRedirectUri: `${window.location.origin}${LocalRoutes.LOGOUT}`,
  },
  cache: {
    cacheLocation: "localStorage",
  },
});

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <React.StrictMode>
      <App pca={msalInstance} />
    </React.StrictMode>,
  );
});

reportAccessibility(React);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
