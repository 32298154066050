import React, { useCallback, useEffect, useRef, useState } from "react";

export interface GooglePdfViewerProps {
  url: string;
  frameTitle: string;
}

/**
 * Display PDFs on mobile devices through google docs
 */
const GooglePdfViewer = ({ url, frameTitle }: GooglePdfViewerProps) => {
  const [iframeTimeoutId, setIframeTimeoutId] = useState(0);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  function iframeLoaded() {
    clearInterval(iframeTimeoutId);
  }
  const getIframeLink = useCallback(() => `https://docs.google.com/gview?url=${url}&embedded=true`, [url]);

  const updateIframeSrc = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.src = getIframeLink();
    }
  }, [getIframeLink]);

  useEffect(() => {
    const intervalId = window.setInterval(updateIframeSrc, 1000 * 3);
    setIframeTimeoutId(intervalId);
  }, [setIframeTimeoutId, updateIframeSrc]);

  return (
    <iframe title={frameTitle} onLoad={iframeLoaded} onError={updateIframeSrc} ref={iframeRef} src={getIframeLink()} />
  );
};

export default GooglePdfViewer;
