// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Markdown from "react-markdown";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckboxChangeEventDetail, IonButton, IonCheckbox, IonIcon, IonInput } from "@ionic/react";
import { addCircleOutline, trashOutline } from "ionicons/icons";
import * as yup from "yup";

import { NxuAlert, NxuComponentLoading, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import CardPanel, { SuccessPanel } from "components/atom/card-panel";

import { useAuthContext } from "utils/context/Auth";
import { usePrivacyAgreementMarkdown, usePrivacySetting, usePrivacySettingUpdate } from "utils/hooks/learners";
import { PrivacySettingPayload } from "types/learner";

import "./privacy-content.scss";

interface PrivacyFieldsProps {
  privacyForm: any;
  isSubmitting: boolean;
  settingsUpdateSubmitting: boolean;
}

const PrivacyFields = (props: PrivacyFieldsProps) => {
  const { privacyForm, isSubmitting, settingsUpdateSubmitting } = props;

  const {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    control,
    formState: { errors },
  } = privacyForm;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "authorizedContacts",
  });

  return (
    <>
      <div className="privacy-setting-form__section">
        <p className={"privacy-setting-form__section-title"}>For All Learners</p>
        <Controller
          control={control}
          name="restricted"
          render={({ field }) => (
            <IonCheckbox
              disabled={settingsUpdateSubmitting}
              slot="start"
              onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                field.onChange(e?.detail.checked);
              }}
              onIonBlur={field.onBlur}
              checked={field.value}
              labelPlacement="end"
              justify="start"
            >
              I wish to restrict the release of my directory information
            </IonCheckbox>
          )}
        />
      </div>
      <div className="privacy-setting-form__section">
        <p className={"privacy-setting-form__section-title"}>For Self-Funded Learners</p>
        <p className={"privacy-setting-form__section-subtitle"}>Education records to be released:</p>
        <Controller
          control={control}
          name="releaseAcademicInfo"
          render={({ field }) => (
            <IonCheckbox
              disabled={settingsUpdateSubmitting}
              slot="start"
              onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                field.onChange(e?.detail.checked);
              }}
              onIonBlur={field.onBlur}
              checked={field.value}
              labelPlacement="end"
              justify="start"
            >
              Academic Information
            </IonCheckbox>
          )}
        />
        <Controller
          control={control}
          name="releaseAccountInfo"
          render={({ field }) => (
            <IonCheckbox
              disabled={settingsUpdateSubmitting}
              slot="start"
              onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                field.onChange(e?.detail.checked);
              }}
              onIonBlur={field.onBlur}
              checked={field.value}
              labelPlacement="end"
              justify="start"
            >
              Learner Account Information
            </IonCheckbox>
          )}
        />
        <p className={"privacy-setting-form__contacts-title"}>
          Authorised person(s) or organisation(s) for access to education records:
          <IonButton
            disabled={settingsUpdateSubmitting}
            aria-label="Add contact row"
            shape="round"
            onClick={() => append({ contactName: "", contactInformation: "" })}
          >
            <IonIcon slot="icon-only" icon={addCircleOutline}></IonIcon>
          </IonButton>
        </p>

        {fields.map((item, index) => (
          <div key={`${item.id}-row`} className="privacy-setting-form__contacts-row">
            <Controller
              key={`${item.id}-name`}
              control={control}
              name={`authorizedContacts.${index}.contactName`}
              render={({ field, fieldState }) => (
                <IonInput
                  disabled={settingsUpdateSubmitting}
                  fill="outline"
                  onInput={field.onChange}
                  onIonBlur={field.onBlur}
                  type="text"
                  placeholder="Contact Name"
                  aria-label="Contact Name"
                  errorText={fieldState.error?.message}
                  className={fieldState.error ? "ion-touched ion-invalid" : ""}
                  value={field.value}
                />
              )}
            />
            <Controller
              key={`${item.id}-info`}
              control={control}
              name={`authorizedContacts.${index}.contactInformation`}
              render={({ field, fieldState }) => (
                <IonInput
                  disabled={settingsUpdateSubmitting}
                  fill="outline"
                  onInput={field.onChange}
                  onIonBlur={field.onBlur}
                  type="text"
                  placeholder="Phone or email"
                  aria-label="Contact Name"
                  errorText={fieldState.error?.message}
                  className={fieldState.error ? "ion-touched ion-invalid" : ""}
                  value={field.value}
                />
              )}
            />
            {index > 0 && (
              <IonButton
                disabled={settingsUpdateSubmitting}
                aria-label="Remove contact row"
                shape="round"
                onClick={() => remove(index)}
              >
                <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
              </IonButton>
            )}
          </div>
        ))}
      </div>
      <div className="privacy-setting-form__section">
        <p className={"privacy-setting-form__section-title"}>For NXU Partner-Affiliated Learners</p>
        <Controller
          control={control}
          name="partnerName"
          render={({ field }) => (
            <IonInput
              disabled={isSubmitting}
              fill="outline"
              onInput={field.onChange}
              onIonBlur={field.onBlur}
              type="text"
              placeholder="Partner Name"
              aria-label="Partner Name"
              errorText={errors.partnerName?.message}
              className={errors.partnerName ? "ion-touched ion-invalid" : ""}
              value={field.value}
              label="I am affiliated with"
            />
          )}
        />

        <p className="privacy-setting-form__section-subtitle">
          I authorise NXU to release my academic and learner account information to the aforementioned third party to
          ensure alignment with the organisation's requirements and expectations
        </p>
      </div>
    </>
  );
};

interface PrivacyFormProps {
  newPrivacyForm: any;
  settingsUpdateSubmitting: boolean;
  settingsUpdateSuccessful: boolean;
  settingUpdateError: string;
  handleSave: SubmitHandler<any>;
}

const PrivacyForm = (props: PrivacyFormProps) => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();

  const { isLoading: privacySettingLoading, error: privacySettingError } = usePrivacySetting(!!userAccount, instance);

  const { newPrivacyForm, settingsUpdateSubmitting, settingsUpdateSuccessful, settingUpdateError, handleSave } = props;

  const { handleSubmit } = newPrivacyForm;

  if (privacySettingLoading) {
    return <NxuComponentLoading />;
  }

  if (privacySettingError) {
    return <NxuAlert type="error" message={"There was an error on loading your current privacy settings"} />;
  }

  return (
    <div>
      {settingsUpdateSuccessful && (
        <SuccessPanel testId="privacy-page__success">
          <p>Privacy settings have been successfully updated</p>
        </SuccessPanel>
      )}
      <form onSubmit={handleSubmit(handleSave)} className="privacy-setting-form" data-testid="privacy-setting-form">
        <PrivacyFields
          privacyForm={newPrivacyForm}
          isSubmitting={settingsUpdateSubmitting}
          settingsUpdateSubmitting={settingsUpdateSubmitting}
        />
        {settingUpdateError && <NxuAlert type="error" message={settingUpdateError} />}
        <NxuPrimaryButton type="submit" expand="block" disabled={settingsUpdateSubmitting}>
          {settingsUpdateSubmitting ? "Updating..." : "Save Settings"}
        </NxuPrimaryButton>
      </form>
    </div>
  );
};

yup.addMethod(yup.array, "unique", function (field, message) {
  return this.test("unique", message, function (array) {
    const uniqueData = Array.from(new Set(array.map((row) => row[field]?.toLowerCase())));
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex((row, i) => row[field]?.toLowerCase() !== uniqueData[i]);
    if (array[index][field] === "") {
      return true;
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

const privacyFormSchema = yup.object({
  restricted: yup.boolean(),
  releaseAcademicInfo: yup.boolean(),
  releaseAccountInfo: yup.boolean(),
  partnerName: yup.string().trim().max(250, "Partner name should not be more than 250 characters"),
  authorizedContacts: yup
    .array()
    .of(
      yup.object().shape({
        contactName: yup.string().trim().max(250, "Contact name should not be more than 250 characters"),
        contactInformation: yup
          .string()
          .trim()
          .when("contactName", {
            is: (val: any) => !!val,
            then: (schema) =>
              schema
                .required("The contact's phone or email is required")
                .max(250, "The Contact information should not be more than 250 characters"),
          }),
      }),
    )
    .unique("contactName", "Contact should not be duplicated"),
});

/**
 * Privacy content section with form
 */
const PrivacyContent = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();

  const [settingsUpdateSuccessful, setSettingsUpdateSuccessful] = useState(false);
  const [settingsUpdateSubmitting, setSettingsUpdateSubmitting] = useState(false);
  const [settingUpdateError, setSettingsUpdateError] = useState<string>();

  const {
    data: privacyAgreement,
    isLoading: privacyAgreementLoading,
    isError: privacyAgreementError,
  } = usePrivacyAgreementMarkdown(!!userAccount, instance);

  const { data: privacySetting, refetch: privacySettingRefetch } = usePrivacySetting(!!userAccount, instance);

  const privacySettingUpdate = usePrivacySettingUpdate();

  const MarkdownContent = () => {
    if (privacyAgreementLoading) {
      return <NxuComponentLoading />;
    }
    if (privacyAgreementError) {
      return <NxuAlert type="error" message={"There was an error on loading the current Privacy Agreement document"} />;
    }

    return <section>{privacyAgreement?.Content && <Markdown>{privacyAgreement?.Content}</Markdown>}</section>;
  };

  const newPrivacyForm = useForm({
    defaultValues: {
      restricted: false,
      releaseAcademicInfo: false,
      releaseAccountInfo: false,
      partnerName: "",
      // @ts-ignore
      authorizedContacts: [{ contactName: "", contactInformation: "" }],
    },
    resolver: yupResolver(privacyFormSchema),
  });

  const {
    setValue,
    formState: { isValid },
  } = newPrivacyForm;

  useEffect(() => {
    if (privacySetting) {
      setValue("restricted", privacySetting.Restricted);
      setValue("releaseAcademicInfo", privacySetting.ReleaseAcademicInfo);
      setValue("releaseAccountInfo", privacySetting.ReleaseAccountInfo);
      setValue("partnerName", privacySetting.PartnerName ? privacySetting.PartnerName : "");

      if (privacySetting.AuthorizedContacts?.length) {
        const contacts = [];
        privacySetting.AuthorizedContacts?.forEach((item) =>
          contacts.push({ contactName: item.ContactName, contactInformation: item.ContactInformation }),
        );
        setValue("authorizedContacts", contacts);
      }
    }
  }, [privacySetting, setValue]);

  const handleSave: SubmitHandler<any> = async (data: PrivacySettingPayload) => {
    if (settingsUpdateSubmitting) return;
    setSettingsUpdateSuccessful(false);
    setSettingsUpdateError("");

    const payload = data;
    payload.authorizedContacts = payload.authorizedContacts.filter((item) => item.contactName?.length);

    if ((payload.releaseAcademicInfo || payload.releaseAccountInfo) && !payload.authorizedContacts?.length) {
      setSettingsUpdateError(
        "Selected privacy settings are invalid. Please specify an authorised contact when allowing the release of Academic or Learner Account Information",
      );
      return;
    }

    if (!isValid) return;
    setSettingsUpdateSubmitting(true);

    privacySettingUpdate.mutate(
      { payload, msalInstance: instance },
      {
        onSuccess() {
          privacySettingRefetch();
          setSettingsUpdateSubmitting(false);
          setSettingsUpdateSuccessful(true);

          const newFormState = JSON.parse(JSON.stringify(data));
          if (newFormState.authorizedContacts.length < 1) {
            newFormState.authorizedContacts = [{ contactName: "", contactInformation: "" }];
          }
          newPrivacyForm.reset(newFormState);
        },
        onError(e) {
          const error = e as HttpError;
          setSettingsUpdateSubmitting(false);
          setSettingsUpdateError(error.message);
        },
      },
    );
  };

  return (
    <div className="privacy-page__content" data-testid="privacy-page__content">
      <CardPanel testId="privacy-page__markdown">
        <h1>Privacy Settings</h1>
        {MarkdownContent()}
      </CardPanel>
      <CardPanel testId="privacy-page__form">
        <PrivacyForm
          newPrivacyForm={newPrivacyForm}
          settingsUpdateSubmitting={settingsUpdateSubmitting}
          settingsUpdateSuccessful={settingsUpdateSuccessful}
          settingUpdateError={settingUpdateError}
          handleSave={handleSave}
        />
      </CardPanel>
    </div>
  );
};

export default PrivacyContent;
