import React from "react";
import { IonButton, useIonAlert } from "@ionic/react";

// Utils
import api from "../../../../utils/campusUI.api";

// Styles
import "./agreement-reject-modal.scss";

export interface AgreementRejectDialogProps {
  dialogDisabled: boolean;
  handleDecline: () => void;
  getBearerToken: () => Promise<string>;
}

/**
 * Present an Alert dialog allowing the user to reject the agreement form
 */
const AgreementRejectDialog = (props: AgreementRejectDialogProps) => {
  const { dialogDisabled, getBearerToken, handleDecline } = props;

  const [presentAlert] = useIonAlert();

  const declineEnrollmentAgreement = async (comment: string | null) => {
    try {
      const response = await api.readmission.declineEnrollmentAgreement(getBearerToken, { comment: comment || "" });
      if (!response.ok) throw await response.json();
      handleDecline.call(false);
    } catch {
      presentAlert({
        header: "Error",
        message: "There was an error on attempting to reject the agreement. Please try again, or contant support",
        buttons: ["OK"],
      });
    }
  };

  const handleSave = async (formValues: { 0: string | null }) => {
    await declineEnrollmentAgreement(formValues["0"]);
  };

  return (
    <IonButton
      type="button"
      color="danger"
      fill="outline"
      disabled={dialogDisabled}
      onClick={() =>
        presentAlert({
          cssClass: "agreement-reject-modal",
          header: "Are you sure?",
          buttons: [
            {
              text: "Close",
              role: "cancel",
            },
            {
              text: "Reject",
              role: "destructive",
              handler: (e) => {
                handleSave(e);
              },
            },
          ],
          inputs: [
            {
              type: "textarea",
              placeholder: "Comments",
              max: 400,
            },
          ],
        })
      }
    >
      Reject
    </IonButton>
  );
};

export default AgreementRejectDialog;
