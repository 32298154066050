import React from "react";
import { useNavigate } from "react-router-dom";

import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import { LocalRoutes } from "constants/routes";

import "./login.scss";

/**
 * MyNXU App LoggedOutPage page
 */
const LoggedOutPage = () => {
  const navigate = useNavigate();
  return (
    <PageContent>
      <section className="login">
        <div className="login__exit-message">
          <p>You've successfully logged out</p>
        </div>
        <NxuPrimaryButton className="login__cta" expand="block" onClick={() => navigate(LocalRoutes.LOGIN)}>
          Return to Login
        </NxuPrimaryButton>
      </section>
    </PageContent>
  );
};

export default LoggedOutPage;
