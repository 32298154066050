import React, { useEffect, useState } from "react";

/**
 * Standardised component for providing greeting based on current client time
 */
const Greeting: React.FC = () => {
  const [greetingText, setGreetingText] = useState<string | null>(null);

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour >= 0 && currentHour < 12) {
      // Time Span: 12:00 AM to 11:59 AM
      setGreetingText("Good morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      // Time Span: 12:00 PM to 5:59 PM
      setGreetingText("Good afternoon");
    } else {
      // Time Span: 6:00 PM to 11:59 PM
      setGreetingText("Good evening");
    }
  }, []);
  return greetingText;
};

export default Greeting;
