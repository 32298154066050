import React from "react";

import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

import { FlutterwaveConfig, CurrencyProvider, FlutterwaveCallback, EdubancConfig } from "types/wallet";
import { AggregateLearnerProfile } from "types/learner";

import { NxuAlert, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";

interface FlutterwaveProps {
  onSubmit: (data: FlutterwaveCallback) => void;
  onOpenFlutterwave: () => void;
  onCancel: () => void;
  submitting?: boolean;
  paymentIsOpen: boolean;
  // Following props are required for flutterwave config initialisation
  flutterwaveConfig: FlutterwaveConfig | EdubancConfig;
  applicantPersonalData: AggregateLearnerProfile;
  learnerId: string;
  currencyProvider: CurrencyProvider;
  reference: string;
  initiator: string;
  currentInvoiceId: string | null;
}

/**
 * Payment interface component for Flutterwave
 */
export default function Flutterwave({
  paymentIsOpen,
  onOpenFlutterwave,
  onSubmit,
  onCancel,
  submitting,
  flutterwaveConfig,
  applicantPersonalData,
  learnerId,
  currencyProvider,
  reference,
  initiator,
  currentInvoiceId,
}: FlutterwaveProps) {
  // Confirm whether the config object is an Edubanc instance with sub account details
  const instanceOfEdubanc = (data: FlutterwaveConfig | EdubancConfig): data is EdubancConfig => "SubaccountId" in data;

  // Declare the config necessart to initialise Flutterwace payment
  const config = {
    public_key: flutterwaveConfig.PublicKey,
    tx_ref: currentInvoiceId || flutterwaveConfig.CallbackId,
    subaccounts: instanceOfEdubanc(flutterwaveConfig) ? [{ id: flutterwaveConfig.SubaccountId }] : [],
    amount: currencyProvider.Amount,
    currency: currencyProvider.CurrencyCode,
    payment_options: "card, mpesa",
    meta: {
      NXU_CallbackId: flutterwaveConfig.CallbackId,
      LearnerID: learnerId,
      ExchangeRate: currencyProvider.ExchangeRate,
      Reference: reference,
      Initiator: initiator,
      NXU_AmountUSD: currencyProvider.AmountUSD,
    },
    customer: {
      email: applicantPersonalData.email,
      phone_number: applicantPersonalData.phoneNumber || "",
      name: `${applicantPersonalData.firstName} ${applicantPersonalData.lastName}`,
    },
    customizations: {
      title: "Nexford University",
      logo: "https://mynxu.nexford.edu/logo.svg",
      description: "",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  if (!applicantPersonalData) {
    return <NxuAlert message="There was an error on loading the Flutterwave payment method"></NxuAlert>;
  }

  return (
    <NxuPrimaryButton
      onClick={() => {
        onOpenFlutterwave();
        handleFlutterPayment({
          callback: (response: any) => {
            onSubmit(response);
            closePaymentModal();
          },
          onClose: () => onCancel(),
        });
      }}
      disabled={submitting || paymentIsOpen}
    >
      {submitting || paymentIsOpen ? "In progress" : "Continue"}
    </NxuPrimaryButton>
  );
}
