import React from "react";

import { NxuCardPanel } from "@nexford/nexford-ui-component-library";

import "./card-panel.scss";

export interface CardPanelProps {
  children: React.ReactNode;
  className?: string;
  testId?: string;
  centre?: boolean;
}

/**
 * Standardised layout panel
 */
const CardPanel = ({ children, className, testId, centre }: CardPanelProps) => (
  <NxuCardPanel className={`${className} mynxu-card-panel`} testId={testId} centre={centre}>
    {children}
  </NxuCardPanel>
);

export default CardPanel;
