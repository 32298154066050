import { useMsal } from "@azure/msal-react";
import { useAuthContext } from "utils/context/Auth";
import { useAepDocumentStatus } from "utils/hooks/learners";
import DocumentsPanel from "components/molecule/documents-panel";
import AepDocumentItem from "components/molecule/aep-document-item";

const AepDocumentsContent = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();

  const {
    data: aepdocuments,
    isLoading: documentStatusLoading,
    error: documentStatusError,
  } = useAepDocumentStatus(!!userAccount, instance);

  const isHidden = !documentStatusLoading && !documentStatusError && aepdocuments?.length === 0;

  if (isHidden) {
    return <></>;
  }
  return (
    <>
      <DocumentsPanel
        title="Admissions Documents"
        documentStatusError={documentStatusError}
        documentStatusLoading={documentStatusLoading}
      >
        {aepdocuments?.map((docItem) => <AepDocumentItem aepDocument={docItem} />)}
      </DocumentsPanel>
    </>
  );
};
export default AepDocumentsContent;
