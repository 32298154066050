import React from "react";
import cx from "classnames";
import { useMsal } from "@azure/msal-react";
import { IonButton, IonIcon, IonPopover } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";

import { isTestEnv } from "constants/feature-flags";
import { NxuAlert, NxuComponentLoading } from "@nexford/nexford-ui-component-library";
import { useTuitionCap } from "utils/hooks/wallet";
import { useLearner } from "utils/hooks/learners";
import { useAuthContext } from "utils/context/Auth";
import CardPanel from "components/atom/card-panel";

import "./tuition-cap.scss";

// Progress Bar section for the Tuition Cap
const TuitionCap = () => {
  const { instance } = useMsal();
  const { userAccount } = useAuthContext();
  const { data: personalInfoData } = useLearner(!!userAccount, instance);
  const {
    data: tuitionCapData,
    isLoading: tuitionCapDataLoading,
    error: tuitionCapDataError,
  } = useTuitionCap(instance, personalInfoData?.programEnrollmentId, isTestEnv);

  if (
    !isTestEnv ||
    !personalInfoData ||
    personalInfoData.productType !== "Degree" ||
    personalInfoData.programStatus !== "enrolled" ||
    (!tuitionCapDataLoading && !tuitionCapDataError && !tuitionCapData?.maxCost)
  ) {
    return null;
  }

  return (
    <CardPanel className="tuition-cap" testId="tuition-cap">
      {tuitionCapDataLoading && <NxuComponentLoading />}
      {tuitionCapDataError && <NxuAlert message={tuitionCapDataError.message} />}
      {!tuitionCapDataLoading && tuitionCapData && (
        <>
          <div className="tuition-cap__title-row">
            <h3>Enrollment Tuition Progress</h3>
            <IonButton
              id="tuition-cap-tooltip-trigger"
              aria-label="Open Enrollment Tuition Progress tooltip"
              shape="round"
              size="small"
            >
              <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
            </IonButton>
            <IonPopover
              data-testid="learner-path-modal"
              className="tuition-cap-tooltip"
              trigger="tuition-cap-tooltip-trigger"
              triggerAction="click"
              side="bottom"
              alignment="start"
            >
              <p>
                Your tuition is capped at 18 months, regardless of the length of your degree program. You can take up to
                24 months to complete your degree, but you will only pay for the first 18 months. This progress bar
                shows how far you are towards completing your maximum tuition period.
              </p>
            </IonPopover>
          </div>
          <div className="tuition-cap__progress-row">
            <div
              className="tuition-cap__progress-bar"
              aria-label={`${tuitionCapData.progressPercentage} percent of the tuition cap has been paid`}
            >
              <div
                className="tuition-cap__progress-bar-inner"
                style={{ width: `${tuitionCapData.progressPercentage}%` }}
              />
              <span
                aria-hidden
                className={cx(
                  "tuition-cap__progress-bar-numeric",
                  tuitionCapData.progressPercentage < 15 && "tuition-cap__progress-bar-numeric--right",
                )}
                style={{
                  left:
                    tuitionCapData.progressPercentage < 15
                      ? `${tuitionCapData.progressPercentage}%`
                      : `calc(${tuitionCapData.progressPercentage}% - 44px)`,
                }}
              >
                {`${tuitionCapData.progressPercentage}%`}
              </span>
            </div>
          </div>
          <div className="tuition-cap__totals-row">
            <span>
              <strong>Total Paid:</strong> ${tuitionCapData.totalPaid}
            </span>
            <span>
              <strong>Max:</strong> ${tuitionCapData.maxCost}
            </span>
          </div>
        </>
      )}
    </CardPanel>
  );
};

export default TuitionCap;
