import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

import config from "config";

/**
 * Object declaration for AppInsights initialisation
 */
export const aiReactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: config.insights.instrumentationKey,
    enableAutoRouteTracking: true,
    // @ts-ignore
    extensions: [aiReactPlugin],
  },
});
appInsights.loadAppInsights();

// Track exception events (Only to be called for error events outside of the AppInsightsContext.Provider)
export const aiTrackException = (error: Error) => {
  appInsights.trackException({ error, severityLevel: SeverityLevel.Error });
};

// Track events (Only to be called for events outside of the AppInsightsContext.Provider)
export const aiTrackEvent = (name: string, customProps: { [key: string]: any }) => {
  appInsights.trackEvent({ name, properties: customProps });
};
