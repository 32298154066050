import React from "react";
import { IonIcon } from "@ionic/react";
import { calendarOutline, logoWhatsapp, mailOutline, logoFacebook } from "ionicons/icons";

import {
  NEXFORD_MAIL_HELP,
  NEXFORD_MAIL_BILLINGS,
  NEXFORD_MAIL_SUCCESS,
  NEXFORD_CALENDAR_SUCCESS,
  NEXFORD_WHATSAPP,
  NEXFORD_WHATSAPP_BILLINGS,
  NEXFORD_WHATSAPP_SUCCESS,
  NEXFORD_MESSENGER,
} from "constants/external-routes";

import CardPanel from "components/atom/card-panel";

import "./get-in-touch.scss";

export interface GetInTouchProps {
  type?: "general" | "success" | "billings" | "feedback";
  children?: React.ReactNode;
}

/**
 * Standardised component for providing mail, whatsapp etc links on a page
 */
const GetInTouch = ({ type = "general", children }: GetInTouchProps) => {
  // Default section title to be used if no child node is included in the props
  const defaultMsgContent = <p>Any questions? Get in touch and we’ll help</p>;

  // Set contact routes based on selected type
  const mailingPath = () => {
    switch (type) {
      case "success": {
        return NEXFORD_MAIL_SUCCESS;
      }
      case "billings": {
        return NEXFORD_MAIL_BILLINGS;
      }
      case "feedback": {
        // myNXU feedback currently sent to the existing Success routes
        return NEXFORD_MAIL_SUCCESS;
      }
      default: {
        return NEXFORD_MAIL_HELP;
      }
    }
  };

  const whatsAppPath = () => {
    switch (type) {
      case "success": {
        return NEXFORD_WHATSAPP_SUCCESS;
      }
      case "billings": {
        return NEXFORD_WHATSAPP_BILLINGS;
      }
      case "feedback": {
        return NEXFORD_WHATSAPP_SUCCESS;
      }
      default: {
        return NEXFORD_WHATSAPP;
      }
    }
  };

  const calendarPath = NEXFORD_CALENDAR_SUCCESS;
  const messengerPath = NEXFORD_MESSENGER;

  return (
    <CardPanel className="get-in-touch" testId="get-in-touch">
      {children || defaultMsgContent}

      <div className="get-in-touch__list">
        <a aria-label="Contact Nexford through Email" className="get-in-touch__item" href={`mailto:${mailingPath()}`}>
          <IonIcon icon={mailOutline} />
        </a>
        {type === "success" && (
          <a aria-label="Schedule an Advising Call" className="get-in-touch__item" target="_blank" href={calendarPath}>
            <IonIcon icon={calendarOutline} />
          </a>
        )}

        {type === "billings" && (
          <a
            aria-label="Contact Nexford through Facebook Messenger"
            className="get-in-touch__item"
            target="_blank"
            href={messengerPath}
          >
            <IonIcon icon={logoFacebook} />
          </a>
        )}
        <a
          aria-label="Chat with us on WhatsApp"
          className="get-in-touch__item"
          target="_blank"
          href={`${whatsAppPath()}&app_absent=0`}
        >
          <IonIcon icon={logoWhatsapp} />
        </a>
      </div>
    </CardPanel>
  );
};

export default GetInTouch;
